import { useQuery } from '@apollo/client'
import { CheckIcon, Container, Dropdown, Image, MetricCounter, Pagination, SearchIcon, Title } from '@ec/ui'
import { UserQuery } from '@ec/types'
import { GET_VOLUNTEER_USERS } from '@ec/apollo/src/queries/user'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'
import useSubscriptionConstraints from 'hooks/useSubscriptionConstraints'

const VolunteersPage = () => {
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState<String>('')
  const [hasDBS, setHasDBS] = useState<Boolean>()
  const [hasDriversLicence, setHasDriversLicence] = useState<Boolean>()
  const [isOver18, setIsOver18] = useState<Boolean>()
  const [sortBy, setSortBy] = useState<String>('')
  const [value] = useDebounce(searchText, 500)
  const { subscriptionLimits } = useSubscriptionConstraints()

  const { data } = useQuery<{ getVolunteers: UserQuery }>(GET_VOLUNTEER_USERS, {

    variables: {
      first: 30,
      page: page,
      filter: {
        search: value === '' ? undefined : value,
        has_dbs: hasDBS,
        has_drivers_license: hasDriversLicence,
        is_over_18: isOver18,
        sortBy: sortBy,
      },
    },
  })

  return (
    <Container>

      <div className="flex items-center gap-3 mb-5">
        <Title className="!m-0 leading-7">Volunteers</Title>
        {
          subscriptionLimits &&
          <MetricCounter limitExceeded={subscriptionLimits.volunteerCount >= subscriptionLimits.volunteerLimit}>{subscriptionLimits.volunteerCount} / {subscriptionLimits.volunteerLimit}</MetricCounter>
        }
      </div>

      <div className="relative flex max-w-[28.125rem] w-full mb-[1.875rem]">
        <SearchIcon className="absolute top-1/2 left-2 transform -translate-y-1/2 w-5 h-5" />
        <input
          type="text"
          className="min-h-[2.875rem] pl-8 rounded-lg border-[0.0625rem] outline-none text-base w-full h-full"
          placeholder="Search"
          onChange={(event) => setSearchText(event.target.value)}
        />
      </div>

      <div className="flex gap-3 mb-[1rem]">
        <Dropdown
          title="Filter by"
          items={[
            {
              text: 'DBS Checked',
              onClick: () => setHasDBS(!hasDBS ? true : undefined),
              icon: hasDBS ? <CheckIcon className='w-4 h-4' /> : undefined,
            },
            {
              text: 'Has Drivers Licence',
              onClick: () => setHasDriversLicence(!hasDriversLicence ? true : undefined),
              icon: hasDriversLicence ? <CheckIcon className='w-4 h-4' /> : undefined,
            },
            {
              text: 'Over 18',
              onClick: () => setIsOver18(!isOver18 ? true : undefined),
              icon: isOver18 ? <CheckIcon className='w-4 h-4' /> : undefined,
            },
          ]}
        />

        <Dropdown
          title="Sort by"
          items={[
            {
              text: 'Name',
              onClick: () => setSortBy('name'),
              icon: 'name' === sortBy ? <CheckIcon className='w-4 h-4' /> : undefined,
            },
            {
              text: 'Email',
              onClick: () => setSortBy('email'),
              icon: 'email' === sortBy ? <CheckIcon className='w-4 h-4' /> : undefined,
            },
            {
              text: 'Experience',
              onClick: () => setSortBy('experience'),
              icon: 'experience' === sortBy ? <CheckIcon className='w-4 h-4' /> : undefined,
            },
          ]}
        />
      </div>

      <div className="overflow-x-auto">
        <table className="w-full table-fixed">
          <tbody>

            <tr>
              <th className="text-left text-sm font-normal bg-muted w-[18.5rem] rounded-l-lg pl-10 py-2">Name</th>
              <th className="text-left text-sm font-normal bg-muted w-[18.5rem]">Email</th>
              <th className="text-left text-sm font-normal bg-muted w-[18.5rem]" colSpan={2}>Experience</th>
              <th className="text-left text-sm font-normal bg-muted w-[18.5rem] rounded-r-lg"></th>
            </tr>

            {
              data?.getVolunteers && data?.getVolunteers.data.map((volunteer, index) => (
                <tr key={`vol-man-opportunities-${volunteer.id}`} className="border-b-2">

                  <td className="py-5">
                    <div className="flex gap-5 pl-10">
                      <div className="h-[3.125rem] w-[3.125rem] bg-muted rounded-lg my-auto">
                        {volunteer.profile_picture
                          ? <Image source={volunteer.profile_picture.url} className="rounded-lg" />
                          : <p className="h-full w-full justify-center bg-primary-blue text-blue-300 font-semibold text-xs lg:text-lg flex items-center rounded-lg">
                            {volunteer.name.split(' ').map(word => word[0]).slice(0, 2)}
                          </p>
                        }
                      </div>
                      <div className="my-auto">
                        <p className="font-semibold">
                          {volunteer.name}
                        </p>
                        <p>
                          {dayjs().diff(volunteer.date_of_birth, 'year')} years old
                        </p>
                      </div>
                    </div>
                  </td>

                  <td>
                    <p className="text-primary-blue break-words">
                      {volunteer.email}
                    </p>
                  </td>

                  <td>
                    <p className="text-xs text-placeholder">Member Since</p>
                    <p className="font-medium">{dayjs(volunteer.created_at).format('YYYY')}</p>
                  </td>

                  <td>
                    <p className="text-xs text-placeholder">Worked with you</p>
                    <p className="font-medium">{volunteer.opportunities_count ?? 0} {(volunteer.opportunities_count ?? 0) === 1 ? ' time' : ' times'}</p>
                  </td>

                  <td className="pr-8 text-right">
                    <a
                      href={`${process.env.REACT_APP_MARKETPLACE_PORTAL_URL}/profile/${volunteer.id}`}
                      className="text-primary-blue"
                    >
                      View Profile
                    </a>
                  </td>

                </tr>
              ))
            }
          </tbody>
        </table>
      </div>

      <hr className="bg-divider mb-5" />

      <div className="flex justify-end mb-6">
        <Pagination
          totalPages={data?.getVolunteers.paginatorInfo?.lastPage || 0}
          onPageChange={(page) => setPage(page)}
        />
      </div>

    </Container>
  )
}

export default VolunteersPage
