import { Button, ChevronIcon, Container, MetricCounter, MultiSelect, SearchIcon, Title } from '@ec/ui'
import { useQuery } from '@apollo/client'
import { GET_VOLUNTEER_MANAGER_OPPORTUNITIES, GET_OPPORTUNITY_FILTERS } from '@ec/apollo/src/queries/opportunities'
import { Opportunity, OpportunityFilters } from '@ec/types'
import { useState } from 'react'
import OpportunitySkeleton from 'components/Skeletons/OpportunitySkeleton'
import OpportunityCard from 'components/OpportunityCard'
import { Link, useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { Tooltip } from 'react-tooltip'
import useSubscriptionConstraints from 'hooks/useSubscriptionConstraints'

const OpportunitiesPage = () => {
  const navigate = useNavigate()

  const { canCreateOpportunities, subscriptionLimits } = useSubscriptionConstraints()

  const [statusSelection, setStatusSelection] = useState<string>('all')
  const [searchText, setSearchText] = useState<string>()
  const [selectedTypes, setSelectedTypes] = useState<string[]>()
  const [debouncedSearchText] = useDebounce(searchText, 500)

  const { data, loading: isLoading } = useQuery<{ getAuthUser: Opportunity }>(GET_VOLUNTEER_MANAGER_OPPORTUNITIES, {
    variables: {
      first: 15,
      page: 1,
      opportunitiesFilters: {
        search: debouncedSearchText ? debouncedSearchText : undefined,
        status: statusSelection ? statusSelection : undefined,
        types: selectedTypes && selectedTypes.length >= 1 ? selectedTypes : undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: opportunityFilterQuery } = useQuery<{ opportunityFilters: OpportunityFilters }>(GET_OPPORTUNITY_FILTERS, {
    variables: {
      fromOrigin: undefined,
    },
  })

  return (
    <div>

      <Container>

        <div className="flex flex-wrap justify-between mb-5 gap-y-4">
          <div className="flex items-center gap-3">
            <Title className="!m-0 leading-7">Opportunities</Title>
            {
              subscriptionLimits &&
              <MetricCounter limitExceeded={subscriptionLimits.opportunityCount >= subscriptionLimits.opportunityLimit}>{subscriptionLimits.opportunityCount} / {subscriptionLimits.opportunityLimit}</MetricCounter>
            }
          </div>
          <div id="create-opportunity-button">
            {
              !canCreateOpportunities &&
              <Tooltip anchorId="create-opportunity-button" content="You have reached the maximum number of opportunities on your subscription plan" />
            }
            <Button disabled={!canCreateOpportunities} onClick={() => navigate('/opportunities/create')}>Create Opportunity</Button>
          </div>
        </div>

        <hr className="bg-divider mb-5" />

        <div className="flex flex-wrap gap-x-[0.9375rem] gap-y-5">
          <div className="relative flex max-w-[28.125rem] w-full">
            <SearchIcon className='absolute top-1/2 left-2 transform -translate-y-1/2 w-5 h-5' />
            <input
              type="text"
              className="min-h-[2.875rem] pl-8 rounded-lg border-[0.0625rem] outline-none text-base w-full h-full"
              placeholder='Search'
              onChange={(event) => setSearchText(event.currentTarget.value)}
            />
          </div>

          <div className='flex flex-wrap gap-3'>
            <Button onClick={() => setStatusSelection('all')} variant={`primary${statusSelection !== 'all' ? '-alt' : ''}`}>All</Button>
            <Button onClick={() => setStatusSelection('pending')} variant={`primary${statusSelection !== 'pending' ? '-alt' : ''}`}>Pending</Button>
            <Button onClick={() => setStatusSelection('live')} variant={`primary${statusSelection !== 'live' ? '-alt' : ''}`}>Live</Button>
            <Button onClick={() => setStatusSelection('past')} variant={`primary${statusSelection !== 'past' ? '-alt' : ''}`}>Past</Button>
            <Button onClick={() => setStatusSelection('promoted')} variant={`primary${statusSelection !== 'promoted' ? '-alt' : ''}`}>Promoted</Button>
            <Button onClick={() => setStatusSelection('cancelled')} variant={`primary${statusSelection !== 'cancelled' ? '-alt' : ''}`}>Cancelled</Button>
          </div>
        </div>

        <div className="mt-5">
          <MultiSelect
            triggerClassName="flex min-w-max border border-[0.0625rem] p-3 rounded-lg"
            trigger={
              <div className="flex items-center gap-3 text-gray px-4">
                Opportunity Types
                <ChevronIcon className="w-4 h-4" />
              </div>
            }
            positionClassName="mt-2"
            items={opportunityFilterQuery?.opportunityFilters.opportunityTypes.map((type) => ({ id: type.id, text: type.name, unavailable: false }))}
            onChange={(types) => setSelectedTypes(types)}
          />
        </div>

        <div className="flex flex-row flex-wrap lg:flex-col gap-[3.125rem] mt-[2.5rem]">
          {
            isLoading &&
            <>
              <OpportunitySkeleton />
              <OpportunitySkeleton />
              <OpportunitySkeleton />
            </>
          }

          {
            data?.getAuthUser.organisation?.opportunities && data?.getAuthUser.organisation.opportunities.length > 0 ? data?.getAuthUser.organisation.opportunities.map((opportunity) => (
              <OpportunityCard key={`opportunities-${opportunity?.id}`} opportunity={opportunity} />
            ))
              :
              <p className="text-text-gray text-sm">We cannot find any opportunities with the given criteria, <Link to="/opportunities/create" className="text-primary-blue hover:underline">click here to get started</Link></p>
          }
        </div>


      </Container>

    </div>
  )
}

export default OpportunitiesPage
