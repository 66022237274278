import { useMutation } from '@apollo/client'
import { Button, Heading, Image, ImageIcon, Input, Toast, FormError, FormMessage } from '@ec/ui'
import { MAX_FILE_SIZE_MESSAGE } from '@ec/ui/src/config/media-library'
import useErrorHandler from 'hooks/UseErrorHandler'
import { UPDATE_ORGANISATION } from '@ec/apollo/src/mutations/organisation'
import { useEffect, useRef, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Controller, useForm } from 'react-hook-form'
import Organisation from '@ec/types/src/organisation'

type OrganisationUpdateForm = {
  name: string
  website: string
  address: string
  secondary_address: string
  city: string
  county: string
  postcode: string
  organisation_image: File
}

const AccountOrganisationPage = () => {
  const { notify } = useNotifier()
  const errorHandler = useErrorHandler()

  const organisationPictureRef = useRef<any>(null)
  const [previewUrl, setPreviewUrl] = useState<string | undefined>()

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const [updateOrganisationDetailsMutation, { data, loading, error }] = useMutation<{ updateOrganisationDetails: Organisation }>(UPDATE_ORGANISATION)

  const { register, handleSubmit, setError, reset, formState: { errors }, control } = useForm<OrganisationUpdateForm>()

  const onSubmit = (form: OrganisationUpdateForm) => {
    updateOrganisationDetailsMutation({
      variables: {
        input: {
          id: user?.organisation.id,
          name: form.name ? form.name : null,
          website: form.website ? form.website : null,
          address: form.address ? form.address : null,
          secondary_address: form.secondary_address ? form.secondary_address : null,
          city: form.city ? form.city : null,
          county: form.county ? form.county : null,
          postcode: form.postcode ? form.postcode : null,
          organisation_image: form.organisation_image ? form.organisation_image[0] : undefined,
        },
      },
    })
  }

  useEffect(() => {
    if (data?.updateOrganisationDetails) {
      notify(<Toast title="Update Successful" message="Your details have been saved" />)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      errorHandler(error, setError)
      notify(<Toast title="Update Unsuccessful" message="There was an issue updating your settings, please try again" type="error" />)
    }
  }, [error])


  useEffect(() => {
    if (user) {
      reset({
        name: user?.organisation?.name,
        website: user?.organisation?.website,
        address: user?.organisation?.address,
        secondary_address: user?.organisation?.secondary_address,
        city: user?.organisation?.city,
        county: user?.organisation?.county,
        postcode: user?.organisation?.postcode,
      })
    }
  }, [user])

  return (
    <>
      <Heading className="mb-5">Organisation Details</Heading>

      <hr className="bg-divider max-w-[900px]" />

      <form onSubmit={handleSubmit(onSubmit)} className="max-w-[800px]">

        <div className="flex flex-col gap-8 mt-8">

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray font-medium mr-16">Logo</p>
            <Controller
              name="organisation_image"
              control={control}
              render={({ field }) => {
                const handleChange = async (files: File[] | FileList | null) => {
                  if (files) {
                    setPreviewUrl(URL.createObjectURL(files[0]))
                  }

                  field.onChange(files)
                }

                return (
                  <div className="w-full max-w-md">
                    <button
                      onClick={() => (organisationPictureRef.current?.click())}
                      type="button"
                      className="group w-full relative bg-white max-w-[200px] h-[200px] flex rounded-md border-divider border shadow-sm focus:border-primary-green focus:outline-none"
                    >
                      <Image source={previewUrl ?? user?.organisation.organisation_image.url} altText="organisation_image" width={250} height={250} className="object-cover w-full h-full rounded-[5px]" />
                      <ImageIcon className="absolute w-5 h-5 right-0 top-0 m-2 text-text-gray-light group-focus:w-6 group-focus:h-6 group-hover:w-6 group-hover:h-6 transition-all group-focus:text-primary-green" />
                    </button>
                    <input
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      className="hidden"
                      ref={organisationPictureRef}
                      onChange={(input) => handleChange(input.target?.files)}
                    />
                    {
                      errors.organisation_image?.message ?
                        <FormError text={errors.organisation_image.message} />
                        :
                        <FormMessage text={MAX_FILE_SIZE_MESSAGE} />
                    }
                  </div>
                )
              }}
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray font-medium">Organisation Name</p>
            <Input
              {...register('name')}
              width="max-w-md"
              error={errors.name?.message}
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray font-medium">Website</p>
            <Input
              {...register('website')}
              width="max-w-md"
              error={errors.website?.message}
            />
          </div>

          <hr className="bg-divider max-w-[900px]" />

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray font-medium">Street Address</p>
            <Input
              {...register('address')}
              width="max-w-md"
              error={errors.address?.message}
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray font-medium">Secondary Address</p>
            <Input
              {...register('secondary_address')}
              width="max-w-md"
              error={errors.secondary_address?.message}
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray font-medium">City</p>
            <Input
              {...register('city')}
              width="max-w-md"
              error={errors.city?.message}
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray font-medium">County</p>
            <Input
              {...register('county')}
              width="max-w-md"
              error={errors.county?.message}
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray font-medium">Postcode</p>
            <Input
              {...register('postcode')}
              width="max-w-md"
              error={errors.postcode?.message}
            />
          </div>

          <div className="flex justify-end lg:mt-6 mt-10">
            <Button isLoading={loading} >Save Changes</Button>
          </div>

        </div>

      </form>


    </>
  )
}

export default AccountOrganisationPage
