import { gql } from '@apollo/client'

export const UPDATE_OPPORTUNITY_FORM = gql`
  mutation ($input: UpdateOpportunityFormInput!) {
    updateOpportunityForm(input: $input) {
      id
      status
      form {
        id
        fields {
          __typename @skip(if: true)
          id
          title
          type
          is_enabled
          is_required
          is_default
        }
      }
    }
  }
`