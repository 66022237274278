import { useMutation, useQuery } from '@apollo/client'
import { GET_OPPORTUNITY } from '@ec/apollo/src/queries/opportunities'
import { Opportunity } from '@ec/types'
import { ChevronIcon, Button, CancelOpportunityModal, CategoryIcon, Container, DateBanner, MapPinIcon, OpportunityImages, Title, Toast, ToolTipButton } from '@ec/ui'
import { Fragment, ReactElement, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNotifier } from 'react-headless-notifier'
import { useNavigate, useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import Alert from 'components/Alert/Alert'
import { OpportunityStatus } from 'enums/OpportunityStatus'
import { SUBMIT_OPPORTUNITY } from '@ec/apollo/src/mutations/opportunity'
import { Menu, Transition } from '@headlessui/react'
import { EyeIcon, IdentificationIcon, InformationCircleIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Bars3CenterLeftIcon } from '@heroicons/react/24/solid'


const OpportunitiesShow = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { notify } = useNotifier()

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)

  const dropdownClassNames: string = 'flex gap-3 items-center w-full text-left p-3'

  const dropdownOptions: { text: string, onClick: () => void, icon: ReactElement }[] = [
    {
      text: 'Edit Opportunity',
      onClick: () => { navigate(`/opportunities/${opportunityQuery?.opportunity?.slug}/edit`) },
      icon: <PencilSquareIcon className='h-5 w-5' />,
    },
    {
      text: 'Preview Opportunity',
      onClick: () => { window.open(`${process.env.REACT_APP_MARKETPLACE_PORTAL_URL}/opportunities/${opportunityQuery?.opportunity.slug}`) },
      icon: <EyeIcon className='h-5 w-5' />,
    },
    {
      text: 'Listing Details',
      onClick: () => { },
      icon: <InformationCircleIcon className='h-5 w-5' />,
    },
    {
      text: 'Application Form',
      onClick: () => { navigate(`/opportunities/${opportunityQuery?.opportunity?.slug}/form/edit`) },
      icon: <Bars3CenterLeftIcon className='h-5 w-5' />,
    },
    {
      text: 'Applicants',
      onClick: () => { navigate('/applicants') },
      icon: <IdentificationIcon className='h-5 w-5' />,
    },
  ]

  const {
    data: opportunityQuery,
    loading: isOpportunityLoading,
    error,
  } = useQuery<{ opportunity: Opportunity }>(GET_OPPORTUNITY,
    {
      variables: {
        id: id,
      },
    },
  )

  const [submitOpportunity, { data: submissionData, loading: isSubmissionLoading, error: submissionError }] = useMutation(SUBMIT_OPPORTUNITY)
  const handleOpportunitySubmission = () => {
    submitOpportunity({
      variables: {
        id: id,
      },
    })
  }

  useEffect(() => {
    if (submissionError) {
      notify(<Toast type="error" message="There was a problem when submitting your Opportunity, try again soon" />)
    }
  }, [submissionError])

  useEffect(() => {
    if (submissionData) {
      notify(<Toast type="success" message="Your opportunity has been submit and is now pending Administrator approval" />)
    }
  }, [submissionData])

  useEffect(() => {
    if (error) {
      navigate('/404')
    }
  }, [error])


  return (
    <div>

      <Container>

        <div>
          <div className='md:flex flex-wrap lg:flex-row lg:justify-between items-center hidden'>
            {
              isOpportunityLoading ?
                <div className="max-w-[11.0625rem] w-full">
                  <Skeleton height={36} className="max-w-[11.0625rem]" />
                </div>
                :
                opportunityQuery?.opportunity.status === 'PUBLISHED' && (
                  <p className="px-4 py-2.5 bg-off-green font-semibold text-sm text-primary-dark rounded-md max-w-[11.0625rem] w-full uppercase">Live Opportunity</p>
                )
            }

            <div className="flex lg:flex-row flex-wrap-reverse items-center gap-2.5 mt-4 lg:mt-0">

              {
                !isOpportunityLoading && opportunityQuery?.opportunity?.status === 'PUBLISHED' &&
                <button
                  className="font-bold text-sm text-red-500 rounded-md max-w-max w-full uppercase px-6 py-3"
                  onClick={() => setIsConfirmationModalOpen(true)}
                >
                  Cancel Opportunity
                </button>
              }

              <div className="flex gap-2.5">
                {
                  isOpportunityLoading ?
                    <Skeleton width={173} height={44} />
                    :
                    <Button variant="secondary" href={`/opportunities/${opportunityQuery?.opportunity?.id}/edit`}>
                      Edit Opportunity
                    </Button>
                }
              </div>

              {
                isOpportunityLoading ?
                  <Skeleton width={208} height={44} />
                  :
                  <Button
                    variant='primary-gray'
                    href={`${process.env.REACT_APP_MARKETPLACE_PORTAL_URL}/opportunities/${opportunityQuery?.opportunity.slug}`}
                    newTab={true}
                  >
                    Preview Opportunity
                  </Button>
              }

            </div>

          </div>

          <hr className="md:block hidden text-divider my-[20px] w-full" />

          <div className='md:flex flex-wrap gap-3 hidden'>
            {
              isOpportunityLoading ?
                <Skeleton width={155} height={40} />
                :
                <Button variant='primary' >Listing Details</Button>

            }
            {
              isOpportunityLoading ?
                <Skeleton width={134} height={40} />
                :
                <Button variant='primary-alt' href={`/opportunities/${opportunityQuery?.opportunity?.id}/form/edit`}>Application Form</Button>
            }
            {
              isOpportunityLoading ?
                <Skeleton width={134} height={40} />
                :
                <Button variant='primary-alt' href='/applicants' newTab={true}>Applicants</Button>
            }
          </div>


          {!isOpportunityLoading ? (
            <Menu as="div" className="md:hidden relative">
              <div>
                <Menu.Button className="text-sm items-center inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                  <p>Options</p>
                  <ChevronIcon className="mr-1 ml-2 h-4 w-4" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className='absolute flex flex-col z-10 mt-2 w-full rounded-md bg-white shadow-lg ring-opacity-5 focus:outline-none border border-grey-300'>
                  {
                    dropdownOptions.map((dropdownOption, index) => {
                      return (
                        <Menu.Item key={index} as={Fragment}>
                          {({ active }) => (
                            <button
                              onClick={dropdownOption.onClick}
                              className={`${dropdownClassNames} ${active && 'bg-light-gray'}`}
                            >
                              {dropdownOption.icon && dropdownOption.icon}
                              {dropdownOption.text}
                            </button>
                          )}
                        </Menu.Item>
                      )
                    },
                    )
                  }
                  {
                    opportunityQuery?.opportunity?.status === 'PUBLISHED' && (
                      <Menu.Item as={Fragment}>
                        {({ active }) => (
                          <button
                            onClick={() => setIsConfirmationModalOpen(true)}
                            className={`${dropdownClassNames} text-white font-semibold bg-primary-danger ${active && 'bg-red-700'} rounded-b-md`}
                          >
                            <TrashIcon className='h-5 w-5' />
                            Cancel Opportunity
                          </button>
                        )}
                      </Menu.Item>
                    )
                  }
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <Skeleton className='w-full h-10 md:hidden' />
          )
          }

          {
            opportunityQuery?.opportunity.status === OpportunityStatus.REJECTED && !submissionData &&
            <div className="mt-4">
              <Alert
                title="Rejected"
                variant="warning"
                actionText="Resubmit"
                onAction={() => handleOpportunitySubmission()}
                isActionLoading={isSubmissionLoading}
              >
                <div className="flex flex-col gap-1">
                  <p className="text-sm font-medium">
                    Your opportunity was rejected by a Go Volunteering administrator, check your email inbox for their reasoning.
                  </p>
                  <p className="text-sm font-medium">
                    You can resubmit your opportunity here when you are ready.
                  </p>
                </div>
              </Alert>
            </div>
          }

          <div className="mt-5 max-w-[61.25rem]">
            {
              isOpportunityLoading ?
                <div className="w-full">
                  <Skeleton height={46} className="max-w-[15rem] lg:max-w-[26.25rem]" />
                </div>
                :
                <Title className="lg:text-3xl text-xl !m-0 !leading-[3.125rem]">{opportunityQuery?.opportunity.title}</Title>
            }

            {
              isOpportunityLoading ?
                <div className="w-full">
                  <Skeleton height={24} className="max-w-[24rem]" />
                </div>
                :
                <div className='flex items-center ml-[-0.125rem]'>
                  <MapPinIcon className="text-primary-blue w-6 h-6" />
                  <p className="lg:text-lg text-sm text-primary-blue">{opportunityQuery?.opportunity.street_address}, {opportunityQuery?.opportunity.city}, {opportunityQuery?.opportunity.postcode}</p>
                </div>
            }

          </div>

          <div className="w-full max-w-[50rem] mt-10">
            <DateBanner
              isLoading={isOpportunityLoading}
              isoDate={opportunityQuery?.opportunity.starts_at}
              subtitle="Start Date"
            />
          </div>

          <div className="w-full max-w-[50rem] mt-2.5 mb-4">
            <DateBanner
              altText="None Specified"
              isLoading={isOpportunityLoading}
              isoDate={opportunityQuery?.opportunity.deadline_at}
              subtitle="Application Deadline"
              isDeadline={true}
            />
          </div>

          <div>

            {/* Images */}
            <OpportunityImages
              isLoading={isOpportunityLoading}
              urls={opportunityQuery?.opportunity?.images?.map((image) => image.url)}
              images={opportunityQuery?.opportunity.images}
            />

            <div className="flex justify-between w-full max-w-[50rem] mt-5 border-divider border-[0.0625rem] rounded-lg py-[1.375rem] px-[1.5625rem]">
              <div className="max-w-[15rem] lg:max-w-[33.75rem]">

                <div className="flex items-center flex-wrap gap-6 text-text-gray text-sm">
                  {
                    isOpportunityLoading ?
                      <Skeleton width={37} height={37} />
                      :
                      <div className="relative ml-[0.25rem] w-[1.625rem] h-[1.625rem] bg-primary-yellow">
                        <CategoryIcon className='mt-[-0.5rem] ml-[-0.3125rem] w-[1.625rem] h-[1.625rem] text-black' />
                      </div>
                  }
                  {
                    isOpportunityLoading ?
                      <Skeleton width={135} height={40} />
                      :
                      opportunityQuery?.opportunity.opportunityTypes.map((type, index) => (
                        <p key={index} className="px-4 py-2.5 bg-off-green font-semibold text-primary-dark rounded-md">{type.name}</p>
                      ))
                  }
                </div>
              </div>
            </div>
          </div>

          <h1 className='text-2xl font-bold mb-[1.375rem] mt-[2.3125rem]'>
            {
              isOpportunityLoading
                ? (
                  <div className="w-full mb-[1.375rem] mt-[2.3125rem]">
                    <Skeleton height={28} className="!max-w-[25rem]" />
                  </div>
                ) : 'Opportunity Description'
            }
          </h1>

          <hr className="text-divider mb-[2.5rem] w-full max-w-[50rem]" />

          <div className='max-w-[45.875rem] w-full ml-0 lg:ml-[1.875rem] mb-[3.75rem]'>
            {
              isOpportunityLoading
                ? (
                  <Skeleton height={24} count={5.45} className="mt-1.5" />
                ) : (
                  <>
                    {
                      opportunityQuery?.opportunity.content &&
                      <ReactMarkdown className="prose prose-stone text-lg">
                        {opportunityQuery.opportunity.content}
                      </ReactMarkdown>
                    }
                  </>
                )
            }
          </div>

          {
            isOpportunityLoading ?
              <div className="w-full mb-[1.375rem] mt-[2.25rem]">
                <Skeleton height={40} className="!max-w-[25rem]" />
              </div>
              :
              <h1 className='text-2xl font-bold mb-[1.375rem] mt-[2.3125rem]'>
                Skills and Credentials
              </h1>
          }

          <hr className="bg-text-gray mb-[2.5rem] w-full max-w-[50rem]" />

          <div className='max-w-[45.875rem] w-full ml-0 lg:ml-[1.875rem] mb-[3.75rem]'>
            {
              isOpportunityLoading
                ? (
                  <Skeleton height={24} count={5.45} className="mt-1.5" />
                ) : (
                  <>
                    {
                      opportunityQuery?.opportunity.skills &&
                      <ReactMarkdown className="prose prose-stone text-lg">
                        {opportunityQuery.opportunity.skills}
                      </ReactMarkdown>
                    }
                  </>
                )
            }
          </div>

          {
            isOpportunityLoading ?
              <div className="w-full mb-[22px] mt-[36px]">
                <Skeleton height={40} className="!max-w-[400px]" />
              </div>
              :
              <div className="flex gap-2">
                <h1 className='text-2xl font-bold mb-[22px] mt-[37px]'>
                  Manager Instructions
                </h1>
                <ToolTipButton
                  id='manager-instructions-tooltip'
                  content='The instructions that your volunteer(s) will use to help them when they start volunteering with you. Examples of this are directions to your location or dress codes for the day'
                  className='h-4 text-text-gray-light mb-[22px] mt-[40px]' type='question'
                />
              </div>
          }

          <hr className="bg-text-gray mb-[40px] w-full max-w-[800px]" />

          <div className='max-w-[734px] w-full ml-0 lg:ml-[30px] mb-[60px]'>
            {
              opportunityQuery?.opportunity.manager_instructions &&
              <ReactMarkdown className="prose prose-stone text-lg">
                {opportunityQuery.opportunity.manager_instructions}
              </ReactMarkdown>
            }
          </div>

        </div>
      </Container>

      <CancelOpportunityModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onSuccess={() => notify(<Toast message={`You have successfully cancelled the "${opportunityQuery?.opportunity.title}" opportunity`} />)}
        opportunity={opportunityQuery?.opportunity}
      />

    </div>
  )
}

export default OpportunitiesShow
