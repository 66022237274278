import { Transition } from '@headlessui/react'
import Skeleton from 'react-loading-skeleton'

const PricingTableSkeleton = () => {
  return (
    <Transition
      appear={true}
      show={true}
      enter="transition ease-in-out duration-300 transform"
      enterFrom="opacity-0 scale-75"
      enterTo="opacity-100 scale-100"
      className="flex-col"
    >
      <div className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm w-full min-h-[450px] max-w-[500px] mx-auto lg:max-w-[375px]">
        <Skeleton width={120} height={28} />
        <div className="mt-4 flex items-end">
          <Skeleton width={156} height={48} />
          <Skeleton width={79} height={28} className="ml-1" />
        </div>
        <div className="mt-6"></div>

        <div className="flex flex-col space-y-6">
          <div className="flex">
            <Skeleton width={24} height={24} />
            <Skeleton width={180} height={24} className="ml-3" />
          </div>

          <div className="flex">
            <Skeleton width={24} height={24} />
            <Skeleton width={230} height={24} className="ml-3" />
          </div>

          <div className="flex">
            <Skeleton width={24} height={24} />
            <Skeleton width={272} height={24} className="ml-3" />
          </div>
        </div>
        <div className="mt-auto">
          <Skeleton height={44} className="mt-auto block" />
        </div>
      </div>
    </Transition>
  )
}

export default PricingTableSkeleton