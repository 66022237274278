import { useMutation } from '@apollo/client'
import { REGISTER_VOLUNTEER_MANAGER } from '@ec/apollo/src/mutations/auth'
import { LoginResponseType } from '@ec/types'
import { Button, DateInput, FormError, Image, ImageIcon, Input, Toast } from '@ec/ui'
import { Subtitle } from '@ec/ui/src/components/Headings'
import useErrorHandler from 'hooks/UseErrorHandler'
import { useEffect, useRef, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login } from 'slices/auth'

type RegisterProps = {
  organisation_name: string,
  organisation_website: string,
  organisation_image: File,
  name: string,
  email: string,
  password: string,
  date_of_birth: string,
}

const RegisterPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { notify } = useNotifier()
  const errorHandler = useErrorHandler()

  const organisationPictureRef = useRef<any>(null)
  const [previewUrl, setPreviewUrl] = useState<string | undefined>()

  const { register, handleSubmit, control, setError, formState: { errors }, clearErrors, setValue } = useForm<RegisterProps>()

  const [registerMutation, { data: registerData, loading: isRegisterLoading, error: registerError }] = useMutation<{ registerVolunteerManager: LoginResponseType }>(REGISTER_VOLUNTEER_MANAGER)

  const onSubmit: SubmitHandler<RegisterProps> = (form) => {
    registerMutation({
      variables: {
        input: {
          name: form.name,
          email: form.email,
          password: form.password,
          date_of_birth: form.date_of_birth,
          organisation_name: form.organisation_name,
          organisation_website: form.organisation_website,
          organisation_image: form.organisation_image ? form.organisation_image[0] : undefined,
        },
      },
    })
  }

  useEffect(() => {
    if (registerData) {
      dispatch(login(registerData.registerVolunteerManager))
      navigate('/')
    }
  }, [registerData])

  useEffect(() => {
    if (registerError) {
      notify(<Toast type="error" title="Registration Error" message="There was an issue registering your account, please try again" />)
    }
  }, [registerError])

  useEffect(() => {
    if (registerError) {
      errorHandler(registerError, setError)
    }
  }, [registerError])

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight mx-auto text-gray-900 lg:max-w-xs">Register your manager account</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">

          <form className="space-y-6" onSubmit={(e) => {
            e.preventDefault()
            clearErrors()
            handleSubmit(onSubmit)(e)
          }}>

            <Subtitle>Organisation details</Subtitle>

            <Input
              label="Name"
              required
              {...register('organisation_name')}
              error={errors.organisation_name?.message}
            />

            <Input
              label="Website"
              required
              {...register('organisation_website')}
              error={errors.organisation_website?.message}
            />

            <div className="relative">
              <label htmlFor="organisation_image" className="block text-sm font-medium text-gray-700 border-b border-divider mb-6 pb-2">Logo / Image</label>
              <Controller
                name="organisation_image"
                control={control}
                render={({ field }) => {
                  const handleChange = async (files: File[] | FileList | null) => {
                    if (files) {
                      setPreviewUrl(URL.createObjectURL(files[0]))
                    }

                    field.onChange(files)
                  }

                  return (
                    <>
                      <button
                        onClick={() => (organisationPictureRef.current?.click())}
                        type="button"
                        className="group w-full relative bg-muted max-w-[200px] h-[200px] mx-auto flex rounded-md border-divider border-dashed border-2 focus:border-primary-green focus:outline-none"
                      >
                        {
                          previewUrl
                            ? <Image source={previewUrl} altText="organisation_image" width={250} height={250} className="object-cover w-full h-full" />
                            : <ImageIcon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 text-text-gray-light hover:w-14 hover:h-14 transition-all group-focus:text-primary-green" />
                        }
                      </button>
                      <input
                        type="file"
                        accept="image/jpeg, image/jpg, image/png"
                        className="hidden"
                        ref={organisationPictureRef}
                        onChange={(input) => handleChange(input.target?.files)}
                      />
                    </>
                  )
                }}
              />
              {
                errors.organisation_image?.message &&
                <div className="absolute">
                  <FormError text={errors.organisation_image.message} />
                </div>
              }
            </div>

            <hr />

            <Subtitle>Personal details</Subtitle>

            <Input
              label="Name"
              required
              {...register('name')}
              error={errors.name?.message}
            />

            <DateInput label="Date of Birth" onChange={(date) => setValue('date_of_birth', date)} error={errors.date_of_birth?.message} />

            <Input
              label="Email"
              required
              {...register('email')}
              error={errors.email?.message}
              expanding
            />

            <Input
              label="Password"
              type="password"
              required
              {...register('password')}
              error={errors.password?.message}
            />

            <Button block isLoading={isRegisterLoading}>
              Register
            </Button>

          </form>
        </div>
      </div>

    </div>
  )
}

export default RegisterPage