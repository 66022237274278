import { Opportunity } from '@ec/types'
import { Image, OpportunityTagsExcerpt, Title } from '@ec/ui'
import { MapPinIcon, CalendarDaysIcon, UsersIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import OpportunityApplicantTable from './OpportunityApplicantTable'
import OpportunityForm from '@ec/types/src/opportunity-form'
import { GET_FORM_SUBMISSIONS } from '@ec/apollo/src/queries/opportunity-form'
import { useQuery } from '@apollo/client'
import OpportunityApplicantBoxSkeleton from 'components/Skeletons/OpportunityApplicantBoxSkeleton'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type OpportunityApplicantBoxTypes = {
  opportunity: Opportunity
}

const OpportunityApplicantBox = ({ opportunity }: OpportunityApplicantBoxTypes) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [lastPage, setLastPage] = useState<number | undefined>(undefined)
  const [numberOfSubmissions, setNumberOfSubmissions] = useState(5)

  dayjs.extend(relativeTime)

  const { filters, selectedOpportunity, opportunityCount } = useSelector((state: RootState) => ({
    filters: state.opportunityApplicants.filters,
    selectedOpportunity: state.opportunityApplicants.selectedOpportunity,
    opportunityCount: state.opportunityApplicants.opportunityCount,
  }))

  const { data, loading } = useQuery<{ form: OpportunityForm }>(GET_FORM_SUBMISSIONS, {
    variables: {
      id: opportunity.form.id,
      first: numberOfSubmissions,
      page: currentPage,
      filters: filters,
    },
  })

  const handlePageChange = (currentPage: number): void => {
    if (data?.form.submissions.paginatorInfo?.lastPage) {
      if (currentPage > data?.form.submissions.paginatorInfo?.lastPage) {
        return
      }
    }

    if (currentPage <= 0) {
      return
    }

    setCurrentPage(currentPage)
  }

  const pages = useMemo(() => {
    if (lastPage) {
      return Array.from(
        new Array(lastPage))
        .map((item, index) => index + 1)
        .splice(
          currentPage <= 2
            ? 0
            : currentPage === lastPage
              ? lastPage - 3
              : currentPage - 2,
          3)
    }
  }, [currentPage, lastPage])

  useEffect(() => {
    if (data) {
      setLastPage(data?.form.submissions.paginatorInfo?.lastPage)
    }
  }, [data])

  useEffect(() => {
    if (selectedOpportunity || opportunityCount === 1) {
      setNumberOfSubmissions(30)
    } else {
      setNumberOfSubmissions(5)
    }
  }, [selectedOpportunity, opportunityCount])

  return (
    <>
      {
        loading ? (
          <OpportunityApplicantBoxSkeleton />
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex justify-between flex-wrap gap-y-4 px-6">

              <div className="flex flex-wrap gap-4">
                <Image height={73} width={130} source={opportunity.images[0].url} className="h-20 rounded-lg object-contain border border-gray" />
                <div className="flex flex-col my-auto">
                  <Title className="mb-1 mt-0 leading-none">{opportunity?.title}</Title>
                  <div className="flex items-center relative ml-[-0.125rem]">
                    <MapPinIcon className="text-primary-blue w-6 h-6" />
                    <p className="lg:text-lg text-sm text-primary-blue">{opportunity?.street_address}, {opportunity?.city}, {opportunity?.postcode}</p>
                  </div>
                  <div className="mt-2.5">
                    <OpportunityTagsExcerpt tags={opportunity.opportunityTypes} />
                  </div>
                </div>
              </div>

              <div className="flex gap-4 my-auto">
                <div className="flex w-10 h-10 bg-muted rounded-lg">
                  <CalendarDaysIcon className="w-5 h-5 m-auto" />
                </div>
                <p className="my-auto font-semibold text-sm lg:text-base">Deadline {dayjs(opportunity.deadline_at).fromNow()}</p>

                <div className="flex gap-2 my-auto">
                  <UsersIcon className="w-5 h-5 m-auto" />
                  <p className="text-sm">{opportunity.acceptedSubmissions} {opportunity.capacity && `/ ${opportunity.capacity} `}Volunteers</p>
                </div>
              </div>

            </div>

            <div className="overflow-x-auto">
              {
                data &&
                <OpportunityApplicantTable applicants={data.form?.submissions?.data} opportunity={opportunity} />
              }
            </div>

          </div>
        )
      }

      {
        !loading &&
        <div className="flex justify-end my-5 mr-1 gap-2">
          <button
            type="button"
            onClick={() => handlePageChange(currentPage - 1)}
            className="pr-2 outline-offset-2 group rounded-md"
            disabled={currentPage === 1}
          >
            <div className="flex items-center justify-end group-disabled:text-placeholder text-primary-dark font-medium">
              <ChevronLeftIcon className="w-4 h-4 inline mr-3" /><span>Prev</span>
            </div>
          </button>
          {
            pages && pages.map((page) => {
              return (
                <button
                  key={`page-${opportunity.id}${page}`}
                  onClick={() => handlePageChange(page)}
                  className={`${currentPage === page ? 'bg-primary-blue text-white' : 'bg-white text-primary-dark'} lg:w-12 lg:h-12 w-8 h-8 rounded-md flex outline-offset-2 outline-primary-blue`}>
                  <p className="m-auto">
                    {page}
                  </p>
                </button>
              )
            })
          }
          <button
            type="button"
            onClick={() => handlePageChange(currentPage + 1)}
            className="pl-2 outline-offset-2 group rounded-md"
            disabled={currentPage === lastPage || data?.form.submissions.data.length === 0}
          >
            <div className="flex items-center justify-end group-disabled:text-placeholder text-primary-dark font-medium">
              <span>Next</span><ChevronRightIcon className="w-4 h-4 inline ml-3" />
            </div>
          </button>
        </div>
      }
    </>
  )
}

export default OpportunityApplicantBox