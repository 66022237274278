import { useMutation } from '@apollo/client'
import { Opportunity, Submission } from '@ec/types'
import { Button, Image, MapPinIcon, Modal, OpportunityTagsExcerpt, Title, Toast } from '@ec/ui'
import { UPDATE_VOLUNTEER_STATUS } from '@ec/apollo/src/mutations/volunteer'
import { CheckCircleIcon, XCircleIcon, DocumentTextIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon as CheckCircleIconOutline, ExclamationTriangleIcon, XCircleIcon as XCircleIconOutline } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { Tooltip } from 'react-tooltip'
import { getDisplayPrice } from '@ec/ui/src/helpers/currency'
import useSubscriptionConstraints from 'hooks/useSubscriptionConstraints'

type OpportunityApplicantRowTypes = {
  applicant: Submission
  opportunity: Opportunity
}

const OpportunityApplicantRow = ({ applicant, opportunity }: OpportunityApplicantRowTypes) => {

  useSelector((state: RootState) => ({ filters: state.opportunityApplicants.filters }))

  const { incrementVolunteerCount, canCreateVolunteers, subscriptionLimits } = useSubscriptionConstraints()

  const validClassNames = 'flex m-auto h-6 w-6 text-primary-yellow'
  const invalidClassNames = 'flex m-auto h-6 w-6 text-red-500'
  const yearsOld = new Date().getFullYear() - new Date(applicant?.user?.date_of_birth).getFullYear()
  const [applicationStatus, setApplicationStatus] = useState<string>(applicant.status)

  const [isAcceptConfirmationModalOpen, setIsAcceptConfirmationModalOpen] = useState(false)
  const [isRejectConfirmationModalOpen, setIsRejectConfirmationModalOpen] = useState(false)
  const [isInformationModalOpen, setIsInformationModalOpen] = useState(false)

  const { notify } = useNotifier()

  const [updateVolunteerStatusMutation, {
    data,
    loading: isLoading,
    error: isError,
  }] = useMutation<{ updateOpportunityVolunteerStatus: any }>(UPDATE_VOLUNTEER_STATUS)


  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  useEffect(() => {
    if (!isLoading && data && !isError) {
      setIsAcceptConfirmationModalOpen(false)
      setIsRejectConfirmationModalOpen(false)
      setIsInformationModalOpen(false)
      setApplicationStatus(data.updateOpportunityVolunteerStatus.status)

      if (!applicant.opportunities_count) {
        // Increment local state if applicant is unique
        incrementVolunteerCount()
      }

      notify(<Toast type="success" message="Applicant updated successfully" />)
    } else if (!isLoading && isError) {
      notify(<Toast type="success" message="There was an issue processing this request, please try again later" />)
    }

  }, [isLoading, data])

  const onApplicantAccept = () => {
    if (opportunity) {
      updateVolunteerStatusMutation({
        variables: {
          submission_id: parseInt(applicant.id),
          status: 'ACCEPTED',
        },
      },
      )
    }
  }

  const onApplicantReject = () => {
    if (opportunity) {
      updateVolunteerStatusMutation({
        variables: {
          status: 'DECLINED',
          submission_id: parseInt(applicant.id),
        },
      },
      )
    }
  }

  return (
    <>
      <tr className="border-b-2">

        <td className="px-4 py-3">
          <div className="flex gap-5">

            <div className="h-[50px] w-[50px] bg-muted rounded-lg my-auto cursor-pointer" onClick={() => setIsInformationModalOpen(true)}>
              {applicant?.user?.profile_picture
                ? <Image source={applicant?.user?.profile_picture.url} className="rounded-lg" />
                : <p className="h-full w-full justify-center bg-primary-blue text-blue-300 font-semibold text-xs lg:text-lg flex items-center rounded-lg">
                  {applicant.user.name.split(' ').map(word => word[0]).slice(0, 2)}
                </p>
              }
            </div>

            <div className="flex flex-col my-auto">
              <span className="font-semibold">
                {applicant?.user?.name}
              </span>
              <span>
                {yearsOld} Years old
              </span>
            </div>

          </div>
        </td>

        <td>
          <a href={`mailto:${applicant?.user?.email}`} className="text-primary-blue hover:underline">
            {applicant?.user?.email}
          </a>
        </td>

        <td>
          {
            applicant.opportunities_count > 0
              ? <CheckCircleIcon className={validClassNames} />
              : <XCircleIcon className={invalidClassNames} />
          }
        </td>

        <td>
          {
            applicant?.user?.has_dbs
              ? <CheckCircleIcon className={validClassNames} />
              : <XCircleIcon className={invalidClassNames} />
          }
        </td>

        <td>
          {
            applicant?.user?.has_drivers_license
              ? <CheckCircleIcon className={validClassNames} />
              : <XCircleIcon className={invalidClassNames} />
          }
        </td>

        <td>
          <div className="flex gap-2 justify-center">
            {applicationStatus !== 'ACCEPTED' && applicationStatus !== 'DECLINED'
              ? <Button variant='secondary' id={`application-button-${applicant.id}`} onClick={() => setIsInformationModalOpen(true)}>
                <div className="flex gap-2 items-center">
                  <Tooltip anchorId={`application-button-${applicant.id}`} content='Click to view the users application' />
                  <DocumentTextIcon className='h-6 w-6' />
                  Application
                </div>
              </Button>
              : applicationStatus === 'DECLINED'
                ? <>
                  <Tooltip anchorId={`rejected-applicant-${applicant.id}`} content='This applicant has already been rejected.' />
                  <div id={`rejected-applicant-${applicant.id}`} className='hover:cursor-help py-1 px-2 bg-red-200 text-red-700 rounded my-auto text-center'>Rejected</div>
                </>
                : <>
                  <Tooltip anchorId={`accepted-applicant-${applicant.id}`} content='This applicant has already been accepted.' />
                  <div id={`accepted-applicant-${applicant.id}`} className='hover:cursor-help py-1 px-2 bg-green-200 text-green-700 rounded my-auto text-center'>Accepted</div>
                </>
            }
          </div>
        </td>

      </tr>

      <Modal size="medium" isOpen={isAcceptConfirmationModalOpen} onClose={() => setIsAcceptConfirmationModalOpen(false)}>
        <Modal.Header title="Accept Applicant" />
        <Modal.Content>
          {
            subscriptionLimits &&
            <>
              <div className="mb-4">
                {applicant.opportunities_count > 0 ?
                  '' :
                  <>
                    {
                      subscriptionLimits.volunteerMinimum <= subscriptionLimits.volunteerCount &&
                      <div className="flex gap-2 p-4 bg-yellow-200 rounded-lg outline outline-yellow-300 text-sm">
                        <div>
                          <ExclamationTriangleIcon className='h-6' />
                        </div>
                        <div className="flex flex-col gap-4">
                          <p>
                            You will be adding one additional volunteer at an extra <strong>{getDisplayPrice(user!.organisation.subscription.priceInformation.seatPrice, 'gbp')} per month</strong>
                          </p>
                          <p>
                            You will be charged immediately for this action and your monthly total going forward will be will be <strong>{getDisplayPrice(user!.organisation.subscription.priceInformation.monthlyTotal + user!.organisation.subscription.priceInformation.seatPrice, 'gbp')}</strong>
                          </p>

                          <p className="text-xs"><strong>Disclaimer:</strong> The prices shown are not inclusive of any discounts or trials you may have applied</p>
                        </div>
                      </div>
                    }
                  </>

                }
              </div>
              <p className="my-10">Are you sure you want to accept this applicant? You can undo this change at any time. <strong>The applicant will be notified of this action.</strong></p>
            </>
          }

        </Modal.Content>
        <Modal.Footer>
          {
            subscriptionLimits &&
            <div className="flex gap-2 mt-auto justify-end">
              <Button variant="secondary" onClick={() => setIsAcceptConfirmationModalOpen(false)}>Cancel</Button>
              <Button onClick={onApplicantAccept} isLoading={isLoading}>Accept Applicant</Button>
            </div>
          }
        </Modal.Footer>
      </Modal>

      <Modal size="medium" isOpen={isRejectConfirmationModalOpen} onClose={() => setIsRejectConfirmationModalOpen(false)}>
        <Modal.Header title="Reject Applicant" />
        <Modal.Content>
          <div className="my-10">Are you sure you want to reject this applicant? This action is irreversible and they will have to apply again. <strong>The applicant will be notified of this action.</strong></div>
        </Modal.Content>
        <Modal.Footer>
          <div className="flex gap-2 mt-auto justify-end">
            <Button variant="secondary" onClick={() => setIsRejectConfirmationModalOpen(false)}>Cancel</Button>
            <Button variant="danger" onClick={onApplicantReject} isLoading={isLoading}>Reject Applicant</Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal isOpen={isInformationModalOpen} onClose={() => setIsInformationModalOpen(false)} size="large">
        <Modal.Header title={
          <>
            <Title>Applicant Submission</Title>
            <div className="flex flex-wrap gap-4 mb-10 mt-5">
              <Image height={73} width={130} source={opportunity.images[0].url} className="h-20 rounded-lg object-contain border border-gray" />
              <div className="flex flex-col my-auto">
                <p className="mb-1 mt-0 leading-none text-xl">{opportunity?.title}</p>
                <div className="flex items-center relative ml-[-0.125rem]">
                  <MapPinIcon className="text-primary-blue w-6 h-6" />
                  <p className="lg:text-base text-sm text-primary-blue">{opportunity?.street_address}, {opportunity?.city}, {opportunity?.postcode}</p>
                </div>
                <div className="mt-2.5">
                  <OpportunityTagsExcerpt tags={opportunity.opportunityTypes} />
                </div>
              </div>
            </div>
          </>
        } subtitle={
          <div className="flex flex-wrap items-center gap-5 justify-between">
            <div className="flex gap-5">
              <div className="flex h-[50px] w-[50px] bg-muted rounded-lg my-auto cursor-pointer" onClick={() => setIsInformationModalOpen(true)}>
                {applicant?.user?.profile_picture
                  ? <Image source={applicant?.user?.profile_picture.url} className="rounded-lg" />
                  : <p className="h-full w-full justify-center bg-primary-blue text-blue-300 font-semibold text-xs lg:text-lg flex items-center rounded-lg">
                    {applicant.user.name.split(' ').map(word => word[0]).slice(0, 2)}
                  </p>
                }
              </div>

              <div className="flex flex-col my-auto">
                <span className="font-semibold">
                  {applicant?.user?.name}
                </span>
                <span>
                  {yearsOld} Years old
                </span>
              </div>
            </div>

            <a
              href={`${process.env.REACT_APP_MARKETPLACE_PORTAL_URL}/profile/${applicant.user.id}`}
              className="text-primary-blue text-base underline" target="_blank" rel="noreferrer"
            >
              View Profile
            </a>
          </div>
        } />
        <Modal.Content className="flex flex-col gap-4 min-h-[20rem] max-h-[40rem] overflow-y-auto">
          <hr />
          {
            applicant.data && applicant.data?.map((sub, index) => {
              return (
                <div key={index}>
                  <h3 className="text-lg font-semibold">{sub.question}</h3>
                  <p>{sub.answer}</p>
                </div>
              )
            })
          }
        </Modal.Content>
        <Modal.Footer>
          <div className="flex gap-2 justify-end">
            <Button variant="secondary" onClick={() => setIsRejectConfirmationModalOpen(true)}>
              <div className="flex gap-2 items-center">
                Reject Applicant
                <XCircleIconOutline className='w-6 h-6' />
              </div>
            </Button>

            <div id="create-volunteers-button">
              {
                !canCreateVolunteers &&
                <Tooltip anchorId="create-volunteers-button" content="You have reached the maximum number of volunteers on your subscription plan" />
              }
              <Button disabled={!canCreateVolunteers} variant="primary" onClick={() => setIsAcceptConfirmationModalOpen(true)}>
                <div className="flex gap-2 items-center">
                  Accept Applicant
                  <CheckCircleIconOutline className='w-6 h-6' />
                </div>
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default OpportunityApplicantRow