export const subscriptionTypeResolver = (type) => {
  switch (type) {
    case 'ESSENTIAL':
      return 'Essential License'
    case 'PROFESSIONAL':
      return 'Professional License'
    case 'ADVANCED':
      return 'Advanced License'
    default:
      return;
  }
}