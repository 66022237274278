import { Button } from '@ec/ui'
import { ReactNode } from 'react'
import classNames from 'classnames'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'

type AlertProps = {
  title: String
  variant?: 'success' | 'warning'
  children: ReactNode
  actionText?: ReactNode
  onAction?: () => void
  isActionLoading?: boolean
}

const Alert = ({
  title,
  children,
  variant = 'success',
  actionText,
  onAction = () => {},
  isActionLoading = false,
}: AlertProps) => {

  const iconClass = 'w-6 h-6 mr-1.5 inline'
  const alertVariant = classNames('w-full p-4 rounded-md', {
    'bg-primary-green text-white': variant === 'success',
    'bg-primary-yellow text-primary-black': variant === 'warning',
  })

  return (
    <div className={alertVariant}>

      <div className="flex lg:justify-between lg:gap-48 flex-col lg:flex-row gap-8">

        <div>
          <h1 className="text-lg font-bold flex items-center">
            {
              variant === 'success' &&
              <CheckCircleIcon className={iconClass} strokeWidth={2} />
            }
            {
              variant === 'warning' &&
              <ExclamationTriangleIcon className={iconClass} strokeWidth={2} />
            }
            {title}
          </h1>
          <div className="mt-2">{children}</div>
        </div>

        {
          actionText &&
          <div className="mr-auto lg:ml-auto lg:mr-0">
            <Button type="button" onClick={onAction} isLoading={isActionLoading}>
              {actionText}
            </Button>
          </div>
        }

      </div>

    </div>
  )
}

export default Alert