import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface OpportunityApplicantsState {
  opportunityCount: undefined | number
  selectedOpportunity: undefined | number
  filters: {}
}

const initialState: OpportunityApplicantsState = {
  opportunityCount: undefined,
  selectedOpportunity: undefined,
  filters: {
    searchQuery: undefined,
    status: undefined,
  },
}

export const auth = createSlice({
  name: 'opportunityApplicants',
  initialState,
  reducers: {
    setOpportunityCount: (state, action: PayloadAction<number>) => {
      state.opportunityCount = action.payload
    },
    setSelectedOpportunity: (state, action: PayloadAction<any>) => {
      state.selectedOpportunity = action.payload
    },
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload
    },
  },
})

export const {
  setOpportunityCount,
  setSelectedOpportunity,
  setFilters,
} = auth.actions

export default auth.reducer