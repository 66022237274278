import { Title } from '@ec/ui'
import PricingTable from 'components/PricingTable'
import { Link } from 'react-router-dom'

const SubscriptionPage = () => {
  return (
    <div className="mx-auto max-w-7xl bg-white py-10 px-4 sm:px-6 lg:px-8">
      <Title>
        GoVolunteering Subscription
      </Title>
      <div className="mb-10">
        <p className="mt-6 max-w-xl">
          Choose an affordable plan that&apos;s packed with the best features for engaging your volunteers.
        </p>
        <Link to="/account/settings" className="text-primary-blue underline mt-5 block">Manage your account settings</Link>
      </div>
      <PricingTable />
    </div>
  )
}

export default SubscriptionPage