import { Opportunity } from '@ec/types'
import { Button, CategoryIcon, ChevronIcon, DateBanner, DurationBanner, Image, MapPinIcon, Title } from '@ec/ui'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

type PropTypes = {
  opportunity: Opportunity,
}

const OpportunityCard = ({ opportunity }: PropTypes) => {
  const [statusTooltipMessage, setStatusTooltipMessage] = useState('') 
  
  useEffect(() => {
    switch (opportunity.status) {
      case 'PUBLISHED':
        setStatusTooltipMessage('This opportunity is visible on the public marketplace')
        break

      case 'DRAFT':
        setStatusTooltipMessage('This opportunity is only visible to your organisation')
        break

      case 'PENDING':
        setStatusTooltipMessage('This opportunity is awaiting approval from an administrator. Once approved, it will be visible on the public marketplace')
        break
      
      default:
        break
    }
  }, [])
  
  
  return (
    <>
      <div className="flex lg:flex-row flex-col w-full bg-bue-500 max-w-[400px] lg:max-w-full">

        <Link to={`/opportunities/${opportunity?.id}`}>
          <div className="lg:flex gap-2 w-full lg:max-w-[309px]">
            { opportunity.images[0] &&
              <Image width={309} height={160} source={opportunity?.images[0].url} className="object-cover flex-grow rounded-lg w-full lg:min-w-[309px] lg:h-[160px] border border-gray" />
            }
          </div>
        </Link>


        {/* Details */}
        <div className="w-full lg:ml-10 mt-5 lg:mt-0">
          <div className="flex lg:flex-row lg:justify-between flex-col">

            <div className="w-full max-w-[621px] order-2 lg:order-1">
              <Title className="mb-1 mt-0 leading-none">{opportunity?.title}</Title>
              <div className="flex items-center relative ml-[-2px]">
                <MapPinIcon className="text-primary-blue w-6 h-6" />
                <p className="lg:text-lg text-sm text-primary-blue">{opportunity?.street_address}, {opportunity?.city}, {opportunity?.postcode}</p>
              </div>
              <div className='flex gap-[7px] items-center mt-4'>
                <>
                  <div className="relative ml-[4px] w-[16px] h-[16px] bg-primary-yellow">
                    <CategoryIcon className='mt-[-4px] ml-[-2px] w-4 h-4 text-black' />
                  </div>
                  <p className='text-sm font-medium text-gray'>{opportunity.opportunityTypes.map((type) => type.name).join(', ')}</p>
                </>
              </div>
            </div>

            <div className='flex gap-2 order-1 lg:order-2 mb-4 lg:mb-0'>
              <div>
                <Tooltip
                  anchorId={`opportunityStatus-${opportunity.id}`}
                  style={{maxWidth: '15rem'}}
                  content={statusTooltipMessage}
                />
                <div id={`opportunityStatus-${opportunity.id}`} className={`${opportunity.status === 'PUBLISHED' ? 'bg-primary-yellow' : 'bg-muted'} items-center gap-2 py-2 lg:py-[13px] inline-flex font-semibold text-xs lg:text-sm px-2 lg:px-5 cursor-help rounded-[5px]`}>
                  {opportunity.status}
                  <InformationCircleIcon className='h-5 w-5'/>
                </div>
              </div>
              <div className="hidden lg:block">
                <Button variant="secondary" href={`/opportunities/${opportunity?.id}`}>
                  <ChevronIcon className="w-5 h-5 -rotate-90" />
                </Button>
              </div>
            </div>
          </div>

          <div className="max-w-[621px] w-full">
            <div className="mt-6">
              <DateBanner isoDate={opportunity?.starts_at} subtitle="Start Date" isLoading={false} />
            </div>

            <div className="mt-2.5">
              <DurationBanner duration={` ${Math.abs(dayjs().diff(dayjs(opportunity.deadline_at), 'day'))} days remaining`} subtitle="Application Deadline" isLoading={false} />
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default OpportunityCard