import { useMutation } from '@apollo/client'
import { CONFIRM_PASSWORD } from '@ec/apollo/src/mutations/auth'
import { Button, Heading, Input } from '@ec/ui'
import { useEffect, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { setConfirmedPassword } from 'slices/auth'

type ConfirmPasswordProps = {
  password: string
}

const ConfirmPasswordPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const search = useLocation().search
  const [searchParams] = useSearchParams()

  const { register, handleSubmit } = useForm<ConfirmPasswordProps>()
  const [confirmPasswordMutation, { data, loading, error }] = useMutation<{ authConfirmPassword: boolean }>(CONFIRM_PASSWORD)

  const returnUrl = useMemo(() => searchParams.get('from') ?? '', [search])

  const onSubmit: SubmitHandler<ConfirmPasswordProps> = (form) => {
    confirmPasswordMutation({
      variables: {
        password: form.password,
      },
    })
  }

  useEffect(() => {
    if (data?.authConfirmPassword) {
      dispatch(setConfirmedPassword(true))
      navigate(`/${decodeURI(returnUrl)}`)
    }
  }, [data])

  return (
    <div className="max-w-[380px] w-full mx-auto py-12">
      <Heading className="mb-5">Confirm Your Password</Heading>
      <hr />
      
      <form className="space-y-4 mt-8" onSubmit={handleSubmit(onSubmit)}>

        <div>
          <Input 
            {...register('password')} 
            label="Your Password" 
            type="password"
            error={error?.graphQLErrors[0].message}
          />
        </div>

        <div>
          <Button block isLoading={loading}>Confirm Password</Button>
        </div>

      </form>
    </div>
  )
}

export default ConfirmPasswordPage