import classNames from "classnames"

type PropTypes = {
  children: React.ReactNode
  limitExceeded?: boolean
}

const MetricCounter = ({ children, limitExceeded = false }: PropTypes) => {
  const innerClassName = classNames('inline-flex items-center flex-col px-3 py-2 rounded text-xs border font-bold bg-muted', {
    'bg-primary-danger text-white': limitExceeded === true,
    'bg-muted text-black': limitExceeded === false
  })
  
  return (
    <div className={innerClassName}>{children}</div>
  )
}

export default MetricCounter