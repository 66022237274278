import { RootState } from '@ec/marketplace/src/store'
import { Footer } from '@ec/ui'
import AuthNavbar from 'components/AuthNavbar'
import GuestNavbar from 'components/GuestNavbar'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

const PageLayout = () => {
  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  return (
    <div className="flex flex-col min-h-screen">
      {
        user ?
          <AuthNavbar />
          :
          <GuestNavbar />
      }
      <Outlet />
      <Footer />
    </div>
  )
}

export default PageLayout