import { gql } from '@apollo/client'
import { ORGANISATION_FIELDS } from '../fragments'

export const UPDATE_ORGANISATION = gql`
  ${ORGANISATION_FIELDS}
  mutation ($input: UpdateOrganisationDetailsInput!) {
      updateOrganisationDetails (input: $input) {
          ...OrganisationFields
      }
  }
`
