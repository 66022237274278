import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { GET_AUTH_VOLUNTEER_MANAGER_USER } from '@ec/apollo/src/queries/auth'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { useQuery } from '@apollo/client'
import { UserType } from '@ec/types'
import { useEffect, useMemo } from 'react'
import { loginViaSession, logout, setAuthChecked } from 'slices/auth'
import { LoadingScreen, Toast } from '@ec/ui'
import { useNotifier } from 'react-headless-notifier'
import useSubscribed from 'hooks/UseSubscribed'
import useVerified from '@ec/ui/src/hooks/useVerified'
import useSubscriptionConstraints from 'hooks/useSubscriptionConstraints'


const ProtectedRoute = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { notify } = useNotifier()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isSubscribed, subscriptionChecked, checkSubscription } = useSubscribed()
  const { isVerified, checkVerification } = useVerified()
  const { canCreateOpportunities } = useSubscriptionConstraints()

  const stripeSessionId: string | null = useMemo(() => searchParams.get('session_id'), [location.pathname + location.search])

  const { isAuthenticated, authChecked, user } = useSelector((state: RootState) => ({
    isAuthenticated: state.auth.authenticated,
    authChecked: state.auth.authChecked,
    user: state.auth.user,
  }))

  const { data, loading, error } = useQuery<{ getAuthUser: UserType }>(GET_AUTH_VOLUNTEER_MANAGER_USER)

  useEffect(() => {
    if (data?.getAuthUser) {
      dispatch(loginViaSession(data.getAuthUser))
    } else if (
      (data?.getAuthUser === null && Object.keys(data).includes('getAuthUser'))
      || (((error as { networkError?: { statusCode: Number } })?.networkError?.statusCode ?? 0) === 419)
    ) {
      notify(<Toast type="warning" message="You must be logged in to view this content." />)
      navigate('/login')
      dispatch(logout())
    }
    if (data || error) {
      dispatch(setAuthChecked(true))
    }
  }, [data, error])

  useEffect(() => {
    if (authChecked) {
      checkSubscription(user?.organisation?.subscription)

      if(user) {
        checkVerification(user)
      }
    }

    if (authChecked && data?.getAuthUser) {
      if (data?.getAuthUser?.context !== 'VOLUNTEER_MANAGER') {
        notify(<Toast type="warning" message="You are not authorised to see this page!" />)
        navigate('/login')
      }
    }
  }, [data, authChecked])

  if (loading || !authChecked || !subscriptionChecked) {
    return (
      <LoadingScreen />
    )
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  if (location.pathname !== '/verify-email' && !isVerified) {
    return <Navigate to="/verify-email"  state={{ from: location }} />
  }

  if (location.pathname === '/verify-email' && isVerified) {
    return <Navigate to="/" />
  }

  if (location.pathname === '/subscription/setup/') {
    if (isSubscribed) {
      return <Navigate to="/" />
    }
    if (!stripeSessionId) {
      return <Navigate to="/404" />
    }
  }

  if (location.pathname !== '/subscription' && !location.pathname.startsWith('/account') && location.pathname !== '/verify-email' && location.pathname !== '/subscription/setup/') {
    if (!isSubscribed) {
      return <Navigate to="/subscription" state={{ from: location }} />
    }
  }

  if (location.pathname === '/opportunities/create' && canCreateOpportunities === false) {
    return <Navigate to="/opportunities" state={{ from: location }} />
  }

  if (location.pathname === '/subscription') {
    if (isSubscribed) {
      return <Navigate to="/" />
    }
  }

  return (
    <div className="flex flex-col h-full w-full mx-auto">
      <Outlet />
    </div>
  )
}

export default ProtectedRoute
