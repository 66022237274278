import { gql } from '@apollo/client'

export const UPDATE_VOLUNTEER_STATUS = gql`
  mutation ($status: OpportunityVolunteerStatus!, $submission_id: Int!) {
      updateOpportunityVolunteerStatus(status: $status, submission_id: $submission_id){
        status
        opportunities_count
      }
  }
`
