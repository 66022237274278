import { useQuery } from '@apollo/client'
import { GET_AUTH_VOLUNTEER_MANAGER_USER } from '@ec/apollo/src/queries/auth'
import { UserType } from '@ec/types'
import { LoadingScreen, Title } from '@ec/ui'
import { Subtitle } from '@ec/ui/src/components/Headings'
import useSubscribed from 'hooks/UseSubscribed'
import { useEffect, useState } from 'react'

const SettingUpAccountPage = () => {
  const { isSubscribed, subscriptionChecked, checkSubscription } = useSubscribed()

  const [subscriptionSearchAttempts, setSubscriptionSearchAttempts] = useState<number>(0)
  const intervalTimeInMs = 5000 // 5 seconds
  const maximumAttempts = 5

  const { startPolling, stopPolling } = useQuery<{ getAuthUser: UserType }>(
    GET_AUTH_VOLUNTEER_MANAGER_USER, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        checkSubscription(data.getAuthUser.organisation.subscription)
        setSubscriptionSearchAttempts(subscriptionSearchAttempts + 1)
      },
    })

  startPolling(intervalTimeInMs)

  useEffect(() => {
    if (subscriptionSearchAttempts < maximumAttempts) {
      if (isSubscribed && subscriptionChecked) {
        stopPolling()
        window.location.assign('/')
      }
    } else {
      stopPolling()
    }
  }, [subscriptionSearchAttempts])

  return (
    <>
      {
        subscriptionSearchAttempts < maximumAttempts
          ? <LoadingScreen message="Setting up your account..." />
          : (
            <div className="h-screen flex items-center justify-center">
              <div className="relative -mt-20 flex flex-col justify-center items-center">
                <Title>We could not verify your account subscription</Title>
                <Subtitle>Please reload the page or get in touch with our team at <a href={'mailto:' + process.env.REACT_APP_CONTACT_EMAIL} className="underline text-primary-blue">{process.env.REACT_APP_CONTACT_EMAIL}</a></Subtitle>
              </div>
            </div>
          )
      }
    </>
  )
}

export default SettingUpAccountPage