import { gql } from '@apollo/client'

export const GET_SUBSCRIPTION_PRODUCTS = gql`
  query {
    subscriptionProducts {
      id
      name
      volunteerMinimum
      metadata {
        features
        recommended
      }
      default_price {
        currency
        type
        unit_amount
        billing_scheme
      }
    }
  }
`