import { SubscriptionStatus } from 'enums/Subscription'
import { useState } from 'react'

const useSubscribed = () => {
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [subscriptionChecked, setSubscriptionChecked] = useState(false)

  const checkSubscription = (subscription) => {
    if (subscription?.status === SubscriptionStatus.ACTIVE || subscription?.status === SubscriptionStatus.TRIALING) {
      if (isSubscribed === false) {
        setIsSubscribed(true)
      }
    } else {
      if (isSubscribed === true) {
        setIsSubscribed(false)
      }
    }

    setSubscriptionChecked(true)
  }

  return { isSubscribed, subscriptionChecked, checkSubscription }
}

export default useSubscribed