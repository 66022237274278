import { useQuery } from '@apollo/client'
import { Container, Title } from '@ec/ui'
import { Subtitle } from '@ec/ui/src/components/Headings'
import { ArrowTrendingDownIcon, ArrowTrendingUpIcon, CalendarDaysIcon, ClockIcon, MapPinIcon, MinusIcon, UserPlusIcon, UsersIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { OpportunityStatistics, SubmissionStatistics, VolunteerManagerDashboard } from '@ec/types/src'
import { GET_VOLUNTEER_MANAGER_DASHBOARD } from '@ec/apollo/src/queries/dashboards'
import Skeleton from 'react-loading-skeleton'
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts'

type TimescaleType = {
  value: 'week' | 'month' | 'year'
  text: String
}

type KpiDetailType = {
  icon: any
  number?: number | string
  description: string
  is_large?: boolean
}

const DashboardPage = () => {
  const [selectedTimescale, setSelectedTimescale] = useState<'week' | 'month' | 'year'>('week')
  const timescales: TimescaleType[] =  [
    { value: 'week', text: 'Week' },
    { value: 'month', text: 'Month' },
    { value: 'year', text: 'Year' },
  ]

  const { data, loading, refetch } = useQuery<{ volunteerManagerDashboard: VolunteerManagerDashboard }>(GET_VOLUNTEER_MANAGER_DASHBOARD, {
    variables: {
      timescale: selectedTimescale,
    },
  })

  useEffect(() => {
    if (selectedTimescale) {
      refetch({
        timescale: selectedTimescale,
      })
    }
  }, [selectedTimescale])

  const getTimescaleRangeString = (scale: 'week' | 'month' | 'year') => {
    if (scale === 'week' || scale === 'month') {
      return dayjs().startOf(scale).format('MMMM')
        + ' ' + dayjs().startOf(scale).format('D')
        + ' - ' + dayjs().endOf(scale).format('MMMM')
        + ' ' + dayjs().endOf(scale).format('D')
    }

    return dayjs().startOf(scale).year() + ' - ' + dayjs().add(1, 'year').year()
  }

  const kpiDetailsSkeletons = Array.from({length: 6}, () => 0)
  const kpiDetails: KpiDetailType[] = useMemo(() => {
    if (!data) return []
    const iconClassnames: string = 'w-10 h-10 p-1.5 bg-muted rounded-md'
    const opportunityStats: OpportunityStatistics = data.volunteerManagerDashboard?.opportunities
    const submissionStats: SubmissionStatistics = data.volunteerManagerDashboard?.submissions
    return [
      { icon: <MapPinIcon className={iconClassnames} />, number: opportunityStats?.active, description: 'active opportunities' },
      { icon: <ClockIcon className={iconClassnames} />, number: opportunityStats?.pending, description: 'pending opportunities' },
      { icon: <CalendarDaysIcon className={iconClassnames} />, number: opportunityStats?.cancellations, description: 'cancellations' },
      { icon: <UserPlusIcon className={iconClassnames} />, number: submissionStats?.new, description: 'new volunteers' },
      { icon: <UsersIcon className={iconClassnames} />, number: submissionStats?.active, description: 'active volunteers' },
      { icon: <ClockIcon className={iconClassnames} />, number: submissionStats?.predicted_hours.toString(), description: 'predicted_volunteering_hours', is_large: true },
    ]
  }, [data])

  function snakeToNormal (text: string) {
    return text.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
  }

  const chartKeys = useMemo(() => {
    if (!data) return
    const allKeys = (data?.volunteerManagerDashboard?.chart.map((bar) => bar.data.map((data) => data.opportunity_name)).join().split(',').filter((bar) => bar !== ''))
    return allKeys.filter((item, index) => allKeys.indexOf(item) === index)
  }, [data])

  const chartData = useMemo(() => {
    if (!data) return
    if (!chartKeys) return

    const bars = data.volunteerManagerDashboard.chart.map((bar) => {
      let object: any = {}
      object.name = bar.group

      if (bar.data.length > 0) {
        bar.data.map((data) => {
          return object[data.opportunity_name] = data.submissions.length
        })
      }

      return object
    })

    return bars
  }, [data])

  const getRandomPastelColor = () => {
    const red = Math.floor(Math.random() * (224 - 96 + 1) + 96)
    const green = Math.floor(Math.random() * (224 - 96 + 1) + 96)
    const blue = Math.floor(Math.random() * (224 - 96 + 1) + 96)

    if (red === green && red === blue) {
      // Ensure that the color is not a shade of white
      return getRandomPastelColor()
    }

    return `rgb(${red}, ${green}, ${blue})`
  }

  const getPillBackgroundColor = (difference?: number) => {
    if (difference === 0 || !difference) {
      return 'bg-muted'
    }

    if (difference > 0) {
      return 'bg-off-green'
    } else {
      return 'bg-red-400'
    }
  }

  const tickFormatter = (currenTick: any) => {
    let newTick: any

    switch (selectedTimescale) {
      case 'week':
        newTick = dayjs(currenTick).format('ddd')
        break
      case 'month':
        newTick = dayjs(currenTick).format('DD MMM')
        break
      case 'year':
        newTick = dayjs(currenTick).format('MMM')
        break
      default:
        newTick = dayjs(currenTick).format('DD/MM/YYYY')
        break
    }

    return newTick
  }

  const labelFormatter = (currentLabel: any) => {
    let newLabel: any

    switch(selectedTimescale) {
      case 'year':
        newLabel = dayjs(currentLabel).format('MMMM YYYY')
        break
      case 'month':
      case 'week':
      default:
        newLabel = dayjs(currentLabel).format('DD/MM/YYYY')
        break
    }

    return newLabel
  }

  return (
    <>

      {/* Page Header */}
      <Container>

        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">

          <div>
            <Title>Dashboard</Title>
            <Subtitle>An overview of your portfolio</Subtitle>
          </div>

          <div className="flex gap-3.5 mt-4 lg:mt-0">

            {
              timescales.map((timescale, index) => (
                <button
                  type="button"
                  key={`scale-${index}`}
                  className="outline-offset-4 outline-primary-blue" onClick={() => setSelectedTimescale(timescale.value)}
                >
                  <p className={`${timescale.value === selectedTimescale ? 'text-primary-dark' : 'text-placeholder'} transition-colors cursor-pointer`}>
                    {timescale.text}
                  </p>
                </button>
              ))
            }

          </div>

        </div>

      </Container>

      {/* Report */}
      <Container className="mt-8">

        <hr className="lg:hidden lg:mb-0 mb-2" />

        <div className="lg:p-9 lg:border lg:rounded-md flex flex-col lg:flex-row lg:items-center lg:justify-between">

          {
            loading
              ? (
                <div className="min-w-[15rem]">
                  <Skeleton height={20} className="max-w-[10.1875rem]" />
                  <Skeleton height={20} className="max-w-[12.1875rem]" />
                </div>
              ) : (
                <div>
                  <h3 className="text-xl leading-6 font-bold capitalize">{`${selectedTimescale}ly`} Report</h3>
                  <Subtitle>{getTimescaleRangeString(selectedTimescale)}</Subtitle>
                </div>
              )
          }


          <div className="flex lg:gap-5 gap-1 lg:items-center flex-col-reverse lg:flex-row lg:mt-0 mt-4">
            <div className="flex flex-wrap gap-x-5 gap-y-1 lg:mt-auto lg:mr-5 mt-2">

              {
                loading
                  ? (
                    <>
                      <div className="min-w-[6.125rem]">
                        <Skeleton height={28} />
                      </div>
                      <div className="min-w-[6.125rem]">
                        <Skeleton height={28} />
                      </div>
                      <div className="min-w-[6.125rem]">
                        <Skeleton height={28} />
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="align-bottom">
                        <span className="text-2xl font-bold">{data?.volunteerManagerDashboard?.submissions?.pending}</span> pending
                      </p>
                      <p className="align-bottom">
                        <span className="text-2xl font-bold">{data?.volunteerManagerDashboard?.submissions?.accepted}</span> accepted
                      </p>
                      <p className="align-bottom">
                        <span className="text-2xl font-bold">{data?.volunteerManagerDashboard?.submissions?.declined}</span> declined
                      </p>
                    </>
                  )
              }

            </div>

            {
              loading
                ? (
                  <div className="min-w-[7.5rem]">
                    <Skeleton height={32} />
                  </div>
                ) : (
                  <p className="align-bottom">
                    <span className="text-3xl font-bold">{data?.volunteerManagerDashboard?.submissions?.total}</span> applicants
                  </p>
                )
            }

            {
              loading
                ? (
                  <div className="min-w-[6.25rem]">
                    <Skeleton height={38} />
                  </div>
                ) : (
                  <div className={`px-4 py-2.5 rounded-md flex items-center max-w-[6.25rem] h-[2.25rem] ${getPillBackgroundColor(data?.volunteerManagerDashboard?.submissions?.difference)}`}>
                    <p className="text-primary-black font-semibold mx-auto mr-2">
                      {data?.volunteerManagerDashboard?.submissions?.difference.toString().padStart(2, '0')}%
                    </p>
                    {
                      data && data.volunteerManagerDashboard.submissions.difference === 0
                        ? (
                          <MinusIcon className="w-7 h-7 text-placeholder flex ml-auto" strokeWidth={2} />
                        ) : data && data.volunteerManagerDashboard.submissions.difference > 0 ? (
                          <ArrowTrendingUpIcon className="w-7 h-7 text-primary-green flex ml-auto" strokeWidth={2} />
                        ) : (
                          <ArrowTrendingDownIcon className="w-7 h-7 text-primary-danger flex ml-auto" strokeWidth={2} />
                        )
                    }
                  </div>
                )
            }

          </div>

        </div>

      </Container>

      {/* Numbers and Charts */}
      <Container className="flex md:flex-row flex-col md:gap-y-0 gap-y-4 mt-8">

        {/* Numbers */}
        <div className="md:w-1/3">
          <div className="md:pr-4 flex flex-col gap-y-5">
            {
              !loading && kpiDetails.map((kpi, index) => {
                return kpi.is_large ? (
                  <div key={`kpi-panels-${index}`} className="min-w-max">
                    <div className='flex items-center'>
                      <ClockIcon className='w-10 h-10 p-1.5 bg-muted rounded-md' />
                      <p className="text-3xl font-bold inline ml-4 mr-2 ">{kpi.number}</p>
                      <p className="mt-2">predicted volunteering hours</p>
                    </div>
                    
                  </div>
                ) : (
                  <div key={`kpi-panels-${index}`} className="flex items-center min-w-max">
                    {kpi.icon}
                    <p className="inline-flex gap-x-2 items-center ml-4 text-left">
                      <span className="text-3xl font-bold inline">{kpi.number}</span> {kpi.description}
                    </p>
                  </div>
                )
              })
            }

            {
              loading && kpiDetailsSkeletons.map((kpi, index) => (
                <div key={`kpi-skeletons-${index}`} className="max-w-[15.625rem]">
                  <Skeleton height={36} />
                </div>
              ))
            }

          </div>
        </div>

        {/* Charts */}
        <div className="md:w-2/3">
          <div className="mb-5">
            {
              loading
                ? (
                  <div className="mx-auto max-w-xs">
                    <Skeleton height={20} />
                  </div>
                ) : (
                  <h3 className="text-lg leading-6 font-bold text-center">Applicants on Opportunity by Date</h3>
                )
            }
          </div>

          <div className="w-full h-full overflow-x-auto overflow-y-auto">

            {
              loading
                ? (
                  <div className="max-w-[780px] ml-auto">
                    <Skeleton height={300} />
                  </div>
                ) : (
                  <BarChart
                    width={780}
                    height={300}
                    data={chartData}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" fontSize={12} tickFormatter={(tick) => tickFormatter(tick)} />
                    <YAxis />
                    <Tooltip isAnimationActive={false} labelFormatter={(label) => labelFormatter(label)} />
                    <Legend />
                    {
                      chartKeys && chartKeys.map((key, index) => {
                        return (
                          <Bar key={`bar-chart-${index}`} dataKey={key} stackId="stack" fill={getRandomPastelColor()} name={snakeToNormal(key)} />
                        )
                      })
                    }
                  </BarChart>
                )
            }
          </div>
        </div>

      </Container>

    </>
  )
}

export default DashboardPage