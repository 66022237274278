import { gql } from '@apollo/client'

export const GET_FORM_SUBMISSIONS = gql`
  query ($id: ID!, $filters: SubmissionsFilter, $first: Int!, $page: Int) {
    form (id: $id) {
        id
        submissions(filters: $filters, first: $first, page: $page) {
            data {
                id
                status
                opportunities_count
                data {
                    type
                    question
                    answer
                }
                user {
                    id
                    profile_picture {
                        id
                        url
                    }
                    name
                    email
                    date_of_birth
                    nickname
                    biography
                    experience
                    credentials
                    opportunities_count
                    has_dbs
                    has_drivers_license
                }
            }
            paginatorInfo {
                lastPage
                currentPage
                total
            }
        }
    }
}
`