import { DateBanner, DurationBanner } from '@ec/ui'
import Skeleton from 'react-loading-skeleton'

const OpportunitySkeleton = () => {
  return (
    <>
      <div className="flex lg:flex-row flex-col w-full bg-bue-500 max-w-[400px] lg:max-w-full">

        <div className="lg:flex gap-2 w-full lg:max-w-[309px]">
          <Skeleton className="block w-full lg:w-[309px] h-[196px] lg:h-[160px] pt-1" />
        </div>

        <div className="w-full lg:ml-10 mt-5 lg:mt-0">
          <div className="flex lg:flex-row lg:justify-between flex-col">

            <div className="w-full max-w-[621px] order-2 lg:order-1">
              <div className="w-full">
                <Skeleton height={32} width={320} className="pt-1 max-w-[200px] lg:max-w-[320px]" />
              </div>
              <div className="w-full">
                <Skeleton height={28} width={280} className="pt-1 mt-1 max-w-[240px] lg:max-w-[280px]" />
              </div>

              <div className='flex gap-[7px] items-center mt-4'>
                <Skeleton width={175} height={20} />
              </div>
            </div>

            <div className='flex gap-2 order-1 lg:order-2 mb-4 lg:mb-0'>
              <div>
                <Skeleton height={44} width={118} />
              </div>
              <div className="hidden lg:block">
                <Skeleton height={44} width={68} />
              </div>
            </div>
          </div>

          <div className="max-w-[621px] w-full">
            <div className="mt-6">
              <DateBanner isLoading={true} />
            </div>

            <div className="mt-2.5">
              <DurationBanner isLoading={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OpportunitySkeleton