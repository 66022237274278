import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { ChevronIcon } from '../../icons'
import { CheckIcon } from '../../icons'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

type DropdownItem = {
  text: string
  href?: string
  disabled?: boolean
  onClick?: () => void
  icon?: JSX.Element
}

type PropTypes = {
  items: DropdownItem[]
  title: string
  direction?: 'left' | 'right'
}

const InnerItem = ({ item }: { item: DropdownItem }) => {
  return (
    <div className="flex items-center">
      {
        item.icon
          ? <div className="mr-3 h-4 w-4">{item.icon}</div>
          : <div className="mr-3 h-4 w-4 hover:text-gray-400 rounded-full bg-gray-200" aria-hidden="true" />
      }
      <p>{item.text}</p>
    </div>
  )
}

const Dropdown = ({
  items,
  title = 'Options',
  direction = 'left',
}: PropTypes) => {

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="text-sm items-center inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <p>{title}</p>
          <ChevronIcon className="-mr-1 ml-2 h-4 w-4" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`absolute ${direction}-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
          <div>
            {
              items.map((item: DropdownItem, index) => {

                return (
                  <Menu.Item as="button" onClick={item.onClick} key={index} className="w-full">
                    {({ active }) => (
                      <div className={classNames('flex items-center px-4 py-2 text-gray-700 text-sm first:rounded-t-md last:rounded-b-md', {
                        'bg-gray-100 text-gray-900 cursor-pointer ring-2 ring-indigo-600': active,
                        'text-gray-300 pointer-events-none': item.disabled,
                      })}>
                        {
                          item.href !== undefined ? (
                            <Link to={item.href}>
                              <InnerItem item={item} />
                            </Link>
                          ) : (
                            <InnerItem item={item} />
                          )
                        }
                      </div>
                    )}
                  </Menu.Item>
                )
              })
            }
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown