import Button from '../../../components/Button'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import '../styles.DatePicker.css'

type PropTypes = {
  onChange?: (date: string | undefined) => void
  layout?: 'dropdown' | 'buttons'
  className?: string
  date?: string
}

const SingleDatePicker = ({
  date,
  className = '',
  onChange,
  layout = 'dropdown',
}: PropTypes) => {
  const [internalSelection, setInternalSelection] = React.useState<Date | undefined>(dayjs(date).toDate() ?? undefined)
  const [isoDate, setIsoDate] = useState<string | undefined>(dayjs(date).toISOString() ?? undefined)
  const currentMonth = dayjs(internalSelection).toDate()

  const handleDateChange: SelectSingleEventHandler = (date?: Date) => {
    setInternalSelection(date)
    setIsoDate(dayjs(date).toISOString())
  }

  const resetRanges = () => {
    setInternalSelection(undefined)
    setIsoDate(undefined)
  }

  useEffect(() => {
    if (onChange) {
      onChange(isoDate ?? undefined)
    }
  }, [isoDate])

  const Footer = () => {
    return (
      <div className="mt-2">
        <Button block variant="secondary" onClick={() => resetRanges()}>
          Remove Selection
        </Button>
      </div>
    )
  }

  return (
    <DayPicker
      defaultMonth={currentMonth}
      className={className}
      mode="single"
      captionLayout={layout}
      fromYear={dayjs().subtract(100, 'year').year()}
      toYear={dayjs().add(2, 'year').year()}
      selected={internalSelection}
      onSelect={handleDateChange}
      footer={<Footer />}
    />
  )
}

export default SingleDatePicker