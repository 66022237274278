import { Opportunity } from '@ec/types'
import { useMutation } from '@apollo/client'
import { Modal, Button, Input } from '@ec/ui'
import { CANCEL_OPPORTUNITY } from '@ec/apollo/src/mutations/opportunity'
import { useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'

type CancellationProps = {
  reason: string
}

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
  opportunity?: Opportunity
}

const CancelOpportunityModal = ({
  isOpen = false,
  onClose,
  onSuccess,
  opportunity,
}: PropTypes) => {
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm<CancellationProps>()

  const [cancelOpportunityMutation,  { loading: isCancelOpportunityLoading }] = useMutation(CANCEL_OPPORTUNITY)

  const onSubmit: SubmitHandler<CancellationProps> = (form) => {
    cancelOpportunityMutation({
      variables: {
        opportunity_id: opportunity?.id,
        input: {
          reason_for_cancellation: form.reason ? form.reason : 'No Reason Provided',
        },
      },
      onCompleted: () => {
        onSuccess()
        onClose()
        navigate('/opportunities')
      },
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>

      <Modal.Header title='Cancel Opportunity' />

      <form onSubmit={handleSubmit(onSubmit)}>

        <Modal.Content className="text-md ">
          <p className="mb-4">
            Are you sure you want to delete this opportunity? If the opportunity is live, it will be removed from the marketplace and your volunteers will be informed of the cancellation. This action can not be undone.
          </p>
          <Input 
            placeholder='Reason for Cancellation' 
            {...register('reason')}
          />
        </Modal.Content>

        <Modal.Footer>
          <div className="flex gap-3 justify-end">
            <Button type="button" variant="secondary" onClick={onClose}>Cancel</Button>
            <Button type="submit" variant="danger" isLoading={isCancelOpportunityLoading}>Confirm</Button>
          </div>
        </Modal.Footer>

      </form>

    </Modal>

  )
}

export default CancelOpportunityModal