import { useMutation } from '@apollo/client'
import { CREATE_PERSONAL_DATA_EXPORT, UPDATE_VOLUNTEER_MANAGER } from '@ec/apollo/src/mutations/auth'
import { UserType } from '@ec/types'
import { Button, DeleteAccountModal, FormError, FormMessage, Heading, Image, ImageIcon, Input, DateInput, Toast } from '@ec/ui'
import { MAX_FILE_SIZE_MESSAGE } from '@ec/ui/src/config/media-library'
import dayjs from 'dayjs'
import useErrorHandler from 'hooks/UseErrorHandler'
import { useEffect, useRef, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateAuthUser } from 'slices/auth'
import { RootState } from 'store'

type AccountSettingsForm = {
  name: string
  email: string
  date_of_birth: string
  profile_picture: File
}

const AccountSettingsPage = () => {
  const [isAccountDeletionModalOpen, setIsAccountDeletionModalOpen] = useState(false)
  const dispatch = useDispatch()
  const { notify } = useNotifier()
  const errorHandler = useErrorHandler()

  const organisationPictureRef = useRef<any>(null)
  const [previewUrl, setPreviewUrl] = useState<string | undefined>()

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))


  const [updateAccountDetailsMutation, { data, loading, error }] = useMutation<{ updateVolunteerManagerAccountDetails: UserType }>(UPDATE_VOLUNTEER_MANAGER)

  const { register, handleSubmit, setError, reset, formState: { errors }, control, getValues, clearErrors, setValue } = useForm<AccountSettingsForm>()


  const [createPersonalDataExportMutation, {
    data: personalDataExportData,
    loading: personalDataExportLoading,
    error: personalDataExportError,
  }] = useMutation(CREATE_PERSONAL_DATA_EXPORT)

  const requestDataExport = () => {
    createPersonalDataExportMutation({
      variables: {
        id: user?.id,
      },
    })
  }

  useEffect(() => {
    if (personalDataExportData) {
      notify(<Toast title="Request Sent" message="A link to download your data should reach your inbox shortly" />)
    }
  }, [personalDataExportData])

  useEffect(() => {
    if (personalDataExportError) {
      notify(<Toast title="Data Request Unsuccessful" message="Please try again later" type="error" />)
    }
  }, [personalDataExportError])

  const onDeleteAccountErrorHandler = () => {
    notify(<Toast title="Request Unsuccessful" message="You may have active opportunity submissions or are actively participating in an opportunity. Please check this and try again." type="error" />)
  }

  const onDeleteAccountSuccessHandler = () => {
    notify(<Toast title="Request Successful" message="Your request to delete your account information has been submitted. Please keep an eye on your email inbox for updates on this." type="success" />)
  }

  const onSubmit: SubmitHandler<AccountSettingsForm> = (form) => {
    updateAccountDetailsMutation({
      variables: {
        input: {
          name: form.name,
          email: form.email,
          date_of_birth: dayjs(form.date_of_birth).format('YYYY-MM-DD'),
          profile_picture: form.profile_picture ? form.profile_picture[0] : undefined,
        },
      },
    })
  }

  useEffect(() => {
    if (user) {
      reset({
        name: user?.name,
        email: user?.email,
        date_of_birth: user?.date_of_birth ?? dayjs().toISOString(),
        profile_picture: undefined,
      })

      setPreviewUrl(user?.profile_picture?.url)
    }
  }, [user])

  useEffect(() => {
    if (data?.updateVolunteerManagerAccountDetails) {
      dispatch(updateAuthUser(data.updateVolunteerManagerAccountDetails))
      notify(<Toast title="Update Successful" message="Your details have been saved" />)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      errorHandler(error, setError)
      notify(<Toast title="Update Unsuccessful" message="There was an issue updating your settings, please try again" type="error" />)
    }
  }, [error])

  return (
    <>

      <Heading className="mb-5">Account Settings</Heading>

      <hr className="bg-divider max-w-[900px]" />

      <form onSubmit={(e) => {
        e.preventDefault()
        clearErrors()
        handleSubmit(onSubmit)(e)
      }} className="max-w-[800px]">

        <div className="flex flex-col gap-8 mt-8">

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray">Profile Picture</p>
            <Controller
              name="profile_picture"
              control={control}
              render={({ field }) => {
                const handleChange = async (files: File[] | FileList | null) => {
                  if (files) {
                    setPreviewUrl(URL.createObjectURL(files[0]))
                  }

                  field.onChange(files)
                }

                return (
                  <div className="w-full max-w-md">
                    <button
                      onClick={() => (organisationPictureRef.current?.click())}
                      type="button"
                      className="group w-full relative bg-white max-w-[200px] h-[200px] flex rounded-md border-divider border shadow-sm focus:border-primary-green focus:outline-none"
                    >
                      <Image source={previewUrl} altText="organisation_image" width={250} height={250} className="object-cover w-full h-full rounded-[5px]" />
                      <ImageIcon className="absolute w-5 h-5 right-0 top-0 m-2 text-text-gray-light group-focus:w-6 group-focus:h-6 group-hover:w-6 group-hover:h-6 transition-all group-focus:text-primary-green" />
                    </button>
                    <input
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      className="hidden"
                      ref={organisationPictureRef}
                      onChange={(input) => handleChange(input.target?.files)}
                    />
                    {
                      errors.profile_picture?.message ?
                        <FormError text={errors.profile_picture.message} />
                        :
                        <FormMessage text={MAX_FILE_SIZE_MESSAGE} />
                    }
                  </div>
                )
              }}
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray">Name</p>
            <Input
              {...register('name')}
              width="max-w-md"
              error={errors.name?.message}
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray">Email</p>
            <Input
              {...register('email')}
              type="email"
              width="max-w-md"
              error={errors.email?.message}
              expanding
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray">Date of Birth</p>
            <div className="max-w-md w-full">
              <DateInput value={getValues('date_of_birth')} onChange={(date) => setValue('date_of_birth', date)} error={errors.date_of_birth?.message} />
            </div>
          </div>

        </div>

        <div className="flex justify-end lg:mt-6 mt-10">
          <Button isLoading={loading}>Save Changes</Button>
        </div>

      </form>

      <div className='flex flex-col max-w-[800px]'>
        <Heading className="mb-5 mt-5">Download Information</Heading>
        <hr className="bg-divider" />
        <p className='mt-8'>To retrieve a file containing all information that is held about you and your organisation on our site, click the button below. You will receive an e-mail with a link to the file when the data is available for download.</p>
        <div className="flex justify-end lg:mt-6 mt-10">
          <Button isLoading={personalDataExportLoading} onClick={requestDataExport}>Request Download</Button>
        </div>
      </div>

      <div className='flex flex-col max-w-[800px]'>
        <Heading className="mb-5 mt-5">Delete Account</Heading>
        <hr className="bg-divider" />
        <p className='mt-8'>
          Deleting your account will remove all of your personal and sensitive information from our records.
          If you are currently an active volunteer, you will not be able to delete your account.
        </p>
        <p className='mt-8'>
          Any information associated to the organisation you are a maneger of will not be removed, if you wish for this information to be removed, please contact the GoVolunteering team.
        </p>
        <div className="flex justify-end lg:mt-6 mt-10 gap-2">
          <Button variant='danger' onClick={() => setIsAccountDeletionModalOpen(true)}>Request account deletion</Button>
        </div>
      </div>

      <DeleteAccountModal
        isOpen={isAccountDeletionModalOpen}
        onClose={() => setIsAccountDeletionModalOpen(false)}
        onSuccess={onDeleteAccountSuccessHandler}
        onError={onDeleteAccountErrorHandler}
      />
    </>
  )
}

export default AccountSettingsPage