import { useMutation } from '@apollo/client'
import { Button, Heading, Toast } from '@ec/ui'
import { CREATE_BILLING_PORTAL } from '@ec/apollo/src/mutations/subscriptions'
import { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'

const AccountBillingPage = () => {
  const { notify } = useNotifier()
  const [loading, setIsLoading] = useState(false)
  const [createBillingPortalMutation, { data, error }] = useMutation<{ createBillingPortal: string }>(CREATE_BILLING_PORTAL)

  useEffect(() => {
    if(data?.createBillingPortal) {
      window.location.href = data.createBillingPortal
    }
  }, [data])

  useEffect(() => {
    if(loading) {
      setIsLoading(false)
    }
  }, [window?.location])

  useEffect(() => {
    if (error) {
      notify(<Toast type="error" title="Loading Error" message="There was an issue requesting the billing portal, please try again" />)

      setIsLoading(false)
    }
  }, [error])

  return (
    <>
      <Heading className="mb-5">Billing Portal</Heading>

      <hr className="bg-divider max-w-[900px]" />

      <p className="max-w-lg mt-8 text-info mb-8">View and manage your active subscription, payment methods and invoices from the Stripe billing portal</p>

      <Button variant="secondary" isLoading={loading} onClick={() => {
        setIsLoading(true)
        createBillingPortalMutation()
      }}>
        Go To Billing Portal
      </Button>
    </>
  )
}

export default AccountBillingPage
