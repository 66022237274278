import { Container } from '@ec/ui'
import { useEffect } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

const AccountPageOutlet = () => {
  const location = useLocation().pathname
  const navigate = useNavigate()

  const links: { url: string, text: string }[] = [
    { url: '/account/settings', text: 'Account Settings' },
    { url: '/account/organisation', text: 'Organisation Details' },
    { url: '/account/billing', text: 'Billing & Invoices' },
    { url: '/account/authentication', text: 'Passwords and Authentication' },
    // { url: '/account/notifications', text: 'Notifications and Preferences' }, This is disabled per ECMVP-457
  ]

  useEffect(() => {
    if (location === '/account') {
      navigate('/account/authentication')
    }
  })

  return (
    <Container>

      <div className="mb-8 max-w-min">

        <div className="flex gap-10 h-full border-b border-divider overflow-x-auto">
          {
            links.map((link, index) => (
              <Link key={index} to={link.url} className={`transition-all whitespace-nowrap font-medium text-sm pb-4 ${link.url === location ? 'border-b border-primary-pink text-primary-pink' : 'text-placeholder'}`}>
                {link.text}
              </Link>
            ))
          }
        </div>

      </div>

      <Outlet />

    </Container>
  )
}

export default AccountPageOutlet