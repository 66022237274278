import { Button, Container, SearchIcon, Select, Title } from '@ec/ui'
import { useQuery } from '@apollo/client'
import { GET_VOLUNTEER_MANAGER_OPPORTUNITIES, GET_VOLUNTEER_MANAGER_OPPORTUNITY_BASIC } from '@ec/apollo/src/queries/opportunities'
import OpportunityApplicantBox from './components/OpportunityApplicantBox'
import { useEffect, useMemo, useState } from 'react'
import OpportunityApplicantBoxSkeleton from 'components/Skeletons/OpportunityApplicantBoxSkeleton'
import { useDispatch, useSelector } from 'react-redux'
import { UserType } from '@ec/types'
import { setFilters, setOpportunityCount, setSelectedOpportunity } from 'slices/opportunityApplicants'
import { RootState } from 'store'
import { useSearchParams } from 'react-router-dom'

const ApplicantsPage = () => {
  const dispatch = useDispatch()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const [status, setStatus] = useState<string | undefined>(undefined)
  const [dropdownOptions, setDropdownOptions] = useState<{ id: string, value: string | null | undefined, label: string }[]>([])

  const [searchParams] = useSearchParams()
  const opportunityParam = searchParams.get('opportunity')

  const { selectedOpportunity } = useSelector((state: RootState) => ({
    selectedOpportunity: state.opportunityApplicants.selectedOpportunity,
  }))

  const { data: opportunityDropdownData, loading: isOpportunityDropdownDataLoading } = useQuery<{ getAuthUser: UserType }>(GET_VOLUNTEER_MANAGER_OPPORTUNITY_BASIC)

  const { data: opportunityData, loading: isOpportunityLoading } = useQuery<{ getAuthUser: UserType }>(GET_VOLUNTEER_MANAGER_OPPORTUNITIES, {
    variables: {
      opportunitiesFilters: {
        id: selectedOpportunity,
      },
    },
  })

  const [opportunities, setOpportunities] = useState<any>([])

  const selectChangeHandler = (opportunity) => {
    let opportunityId = undefined

    if (opportunity.value !== '') {
      opportunityId = opportunity.id
    }

    dispatch(setSelectedOpportunity(opportunityId))
  }

  useEffect(() => {
    if (opportunityDropdownData?.getAuthUser.organisation.opportunities && !isOpportunityDropdownDataLoading) {
      const allOpportunities = opportunityDropdownData.getAuthUser.organisation.opportunities.map((opportunity) => {
        return { id: opportunity.id, value: opportunity.slug, label: opportunity.title }
      })

      setDropdownOptions([{ id: '0', value: '', label: 'All Opportunities' }, ...allOpportunities])
    }
  }, [opportunityDropdownData])

  useEffect(() => {
    if (opportunityData?.getAuthUser.organisation.opportunities) {
      let opportunities = opportunityData.getAuthUser.organisation.opportunities
      setOpportunities(opportunities)
      dispatch(setOpportunityCount(opportunities.length))
    }
  }, [opportunityData])

  useEffect(() => {
    if(searchQuery !== undefined || status !== undefined) {
      dispatch(setFilters({
        searchQuery: searchQuery,
        status: status,
      }))
    }
  }, [searchQuery, status])

  const defaultOpportunityValue = useMemo(() => {
    if (dropdownOptions) {
      const opportunity = dropdownOptions.find((element) => element.id === opportunityParam)
      return opportunity?.id
        ? opportunity.value
        : undefined
    }
  }, [opportunityParam, dropdownOptions]) ?? undefined

  return (
    <div>

      <Container>

        <div className="flex justify-between mb-5">
          <Title className="!m-0 leading-7">Applicants</Title>
        </div>
        <div className="flex flex-col gap-4 mb-[40px]">
          <div className='flex flex-wrap gap-x-[15px] gap-y-5'>
            <div className='relative flex max-w-[450px] w-full'>
              <SearchIcon className='absolute top-1/2 left-2 transform -translate-y-1/2 w-5 h-5' />
              <input
                type="text"
                className="min-h-[46px] pl-8 rounded-lg border-[1px] outline-none text-base w-full h-full"
                placeholder="Search applicants"
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
            <div className='flex flex-wrap gap-3'>
              <Button onClick={() => setStatus(undefined)} variant={`primary${status !== 'ALL' ? '-alt' : ''}`}>All</Button>
              <Button onClick={() => setStatus('PENDING')} variant={`primary${status !== 'PENDING' ? '-alt' : ''}`}>Pending</Button>
              <Button onClick={() => setStatus('ACCEPTED')} variant={`primary${status !== 'ACCEPTED' ? '-alt' : ''}`}>Accepted</Button>
              <Button onClick={() => setStatus('DECLINED')} variant={`primary${status !== 'DECLINED' ? '-alt' : ''}`}>Rejected</Button>
            </div>
          </div>
          <div className="flex max-w-[400px]">
            <Select
              key="opportunity-filter"
              labelText="Filter By Opportunity"
              onChange={selectChangeHandler}
              values={dropdownOptions}
              defaultValue={defaultOpportunityValue}
            />
          </div>
        </div>

        <div className="flex flex-col gap-10">
          {
            isOpportunityLoading
              ? Array.from(new Array(1)).map((index) => <OpportunityApplicantBoxSkeleton key={`loading-opportunity-applicant-${index}`} />)
              : opportunities.map((opportunity, index) => (
                <div key={index} className='flex flex-col'>
                  <OpportunityApplicantBox opportunity={opportunity} />
                </div>
              ),
              )
          }
        </div>

      </Container>

    </div>
  )
}

export default ApplicantsPage
