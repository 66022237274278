import { configureStore } from '@reduxjs/toolkit'
import AuthReducer from 'slices/auth'
import OpportunityApplicantsReducer from 'slices/opportunityApplicants'

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    opportunityApplicants: OpportunityApplicantsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch