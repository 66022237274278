import { useMutation } from '@apollo/client'
import { LOGOUT } from '@ec/apollo/src/mutations/auth'
import { Button, Toast } from '@ec/ui'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from 'slices/auth'
import { RootState } from 'store'
import Navbar from '@ec/ui/src/components/Navbar'
import VmLogo from '@ec/ui/src/svgs/vmLogo'
import useSubscriptionConstraints from 'hooks/useSubscriptionConstraints'
import { Tooltip } from 'react-tooltip'
import classNames from 'classnames'
import { SubscriptionLimits } from '@ec/types'
import { subscriptionTypeResolver } from '@ec/ui/src/helpers/subscription-resolver'

const AuthNavbar = () => {
  const { notify } = useNotifier()
  const dispatch = useDispatch()

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const [logoutMutation, { data: logoutData }] = useMutation(LOGOUT)

  const { canCreateOpportunities } = useSubscriptionConstraints()

  useEffect(() => {
    if (logoutData) {
      notify(<Toast type='info' message='You have signed out successfully' />)
      dispatch(logout())
    }
  }, [logoutData])

  const links: { url: string, text: string, root?: string }[] = [
    { url: '/', text: 'Dashboard' },
    { url: '/opportunities', text: 'Opportunities', root: '/opportunities' },
    { url: '/applicants', text: 'Applicants' },
    { url: '/volunteers', text: 'Volunteers' },
  ]

  return (
    <Navbar
      logo={
        <Link to="/" className="block max-w-[180px] lg:max-w-[260px] w-full">
          <VmLogo className="w-full" />
        </Link>
      }
      links={links}
      actions={
        <>
          <div className="xl:block hidden">
            <div id="nav-create-opportunity-button">
              {
                !canCreateOpportunities &&
                <Tooltip anchorId="nav-create-opportunity-button" content="You have reached the maximum number of opportunities on your subscription plan" />
              }
              <Button disabled={!canCreateOpportunities} type="button" variant="primary-alt" href='/opportunities/create'>
                Create Opportunity
              </Button>
            </div>
          </div>
          {user &&
            <Navbar.Profile
              user={user}
              links={[{
                url: '/account/settings',
                text: 'Account Settings',
              }]}
              logout={logoutMutation}>
              {
                user.organisation.subscriptionLimits &&
                <SubscriptionOverview subscriptionLimits={user.organisation.subscriptionLimits} subscriptionType={user.organisation.subscription.type} />
              }
            </Navbar.Profile>
          }
        </>
      }
      persistActions={true}
    />
  )
}

type SubscriptionOverviewPropTypes = {
  subscriptionLimits: SubscriptionLimits
  subscriptionType: string
}

const SubscriptionOverview = ({ subscriptionLimits, subscriptionType }: SubscriptionOverviewPropTypes) => {

  const opportunityBadgeClassNames = classNames('inline-flex items-center flex-col px-1 rounded text-xs border font-bold', {
    'bg-primary-danger text-white': subscriptionLimits.opportunityCount >= subscriptionLimits.opportunityLimit,
    'bg-muted text-black': subscriptionLimits.opportunityCount < subscriptionLimits.opportunityLimit,
  })

  const volunteerBadgeClassNames = classNames('inline-flex items-center flex-col px-1 rounded text-xs border font-bold', {
    'bg-primary-danger text-white': subscriptionLimits.volunteerCount >= subscriptionLimits.volunteerLimit,
    'bg-muted text-black': subscriptionLimits.volunteerCount < subscriptionLimits.volunteerLimit,
  })

  return (
    <div className="flex flex-col gap-2 px-4 py-2 text-sm font-medium">
      <div>
        <div className="inline-flex items-center flex-col bg-primary-blue text-white px-2 py-1 rounded text-xs border font-bold">{subscriptionTypeResolver(subscriptionType)}</div>
      </div>
      <p><div className={opportunityBadgeClassNames}>{subscriptionLimits.opportunityCount} / {subscriptionLimits.opportunityLimit}</div> Opportunities</p>
      <p><div className={volunteerBadgeClassNames}>{subscriptionLimits.volunteerCount} / {subscriptionLimits.volunteerLimit}</div> Volunteers</p>
    </div>
  )
}


export default AuthNavbar