import { Button } from '@ec/ui'
import { Link } from 'react-router-dom'
import Navbar from '@ec/ui/src/components/Navbar'
import VmLogo from '@ec/ui/src/svgs/vmLogo'

const GuestNavbar = () => {
  return (
    <Navbar
      logo={
        <Link to="/" className="block max-w-[180px] lg:max-w-[260px] w-full">
          <VmLogo className="w-full" />
        </Link>
      }
      actions={
        <>
          <Button type="button" variant="primary-alt" href='/register'>
            Register
          </Button>
          <Button type="button" variant="primary" href='/login'>
            Login
          </Button>
        </>
      }

    />
  )
}

export default GuestNavbar