import { useMutation, useQuery } from '@apollo/client'
import { GET_OPPORTUNITY_FORM } from '@ec/apollo/src/queries/opportunities'
import { UPDATE_OPPORTUNITY_FORM } from '@ec/apollo/src/mutations/opportunity-form'
import { Opportunity } from '@ec/types'
import { Button, Container, Title, Toast, Toggle } from '@ec/ui'
import { Subtitle } from '@ec/ui/src/components/Headings'
import { SubmitHandler, useFieldArray, UseFieldArrayRemove, UseFieldArrayUpdate, useForm } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { EyeIcon, EyeSlashIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { v4 as uuid } from 'uuid'
import { useNotifier } from 'react-headless-notifier'
import useErrorHandler from 'hooks/UseErrorHandler'

type PartialOpportunityFormField = {
    id: string
    key?: string
    title?: string,
    type: string,
    is_enabled: boolean
    is_required: boolean
    is_default: boolean
}

type OpportunityFormProps = {
  opportunity_id: string
  fields: PartialOpportunityFormField[]
}

const OpportunityFormEditPage = () => {
  const { id } = useParams()
  const { notify } = useNotifier()
  const errorHandler = useErrorHandler()
  const navigate = useNavigate()

  const [opportunityStatusOnLoad, setOpportunityStatusOnLoad] = useState<string|undefined>()

  const { handleSubmit, setValue, watch, reset, control, setError, formState: { errors: formErrors } } = useForm<OpportunityFormProps>()
  const { fields: fieldArray, append: fieldArrayAppend, remove: fieldArrayRemove, update: fieldArrayUpdate } = useFieldArray({
    control: control,
    name: 'fields',
    keyName: 'key',
  })

  const {
    data: getOpportunityForm,
    loading: isOpportunityLoading,
  } = useQuery<{ opportunity: Opportunity }>(GET_OPPORTUNITY_FORM,
    {
      skip: false,
      variables: {
        id: id,
      },
    },
  )

  const [updateFormMutation, {
    data: updateOpportunityForm,
    loading: isUpdateFormLoading,
    error: updateOpportunityFormError,
  }] = useMutation<{ updateOpportunityForm: Opportunity }>(UPDATE_OPPORTUNITY_FORM)

  const onSubmit: SubmitHandler<OpportunityFormProps> = (form) => {
    updateFormMutation({
      variables: {
        input: {
          opportunity_id: form.opportunity_id,
          fields: form.fields.map((field) => {
            if (field.title?.trim() === '') {
              field.title = undefined
            }

            return field
          }),
        },
      },
    })
  }

  // Set form on load
  useEffect(() => {
    if (getOpportunityForm) {
      setValue('opportunity_id', getOpportunityForm.opportunity.id, { shouldDirty: false })
      setValue('fields', getOpportunityForm.opportunity.form.fields, { shouldDirty: false })
      setOpportunityStatusOnLoad(getOpportunityForm.opportunity.status)
    }
  }, [getOpportunityForm])

  // Reset form on success
  useEffect(() => {
    if (updateOpportunityForm) {
      if (opportunityStatusOnLoad === 'DRAFT' && updateOpportunityForm.updateOpportunityForm.status === 'PENDING') {
        notify(<Toast title="Your opportunity and application has been saved" message="The Go Volunteering team will review your opportunity before it is made live" />)
        navigate(`/opportunities/${id}`)
      } else {
        notify(<Toast message="Your application form changes have been saved" />)
      }

      reset({
        opportunity_id: updateOpportunityForm.updateOpportunityForm.id,
        fields: updateOpportunityForm.updateOpportunityForm.form.fields,
      })
    }
  }, [updateOpportunityForm])

  // Handle error messages
  useEffect(() => {
    if (updateOpportunityFormError) {
      notify(<Toast type="error" message="Something went wrong, check your form and try again soon"/>)
      errorHandler(updateOpportunityFormError, setError)
    }
  }, [updateOpportunityFormError])

  const handleAppendField = () => {
    fieldArrayAppend({
      id: uuid(),
      title: undefined,
      type: 'text',
      is_default: false,
      is_enabled: true,
      is_required: true,
    })
  }

  const fieldIndex = (id: string) => {
    return fieldArray?.findIndex((field) => field.id === id)
  }

  return (
    <div>

      <Container className="lg:!px-2 px-8 pb-32">

        <form onSubmit={handleSubmit(onSubmit)} className="flex lg:flex-row flex-col lg:justify-between mb-5">
          {
            isOpportunityLoading
              ? <div className="w-full"><Skeleton height={40} className="-mt-1 !max-w-sm" /></div>
              : (
                <Title className="!m-0">
                  {
                    getOpportunityForm?.opportunity.status === 'DRAFT' && !updateOpportunityForm?.updateOpportunityForm
                      ? 'List new opportunity'
                      : getOpportunityForm?.opportunity.title
                  }
                </Title>
              )
          }
          <div className="lg:mt-0 mt-2 max-w-min flex lg:gap-3 gap-2">
            <Button variant="secondary" href={`/opportunities/${id}`}>
              Back to Opportunity
            </Button>
            {
              isOpportunityLoading
                ? <div className="w-full"><Skeleton height={44} width={156} className="!max-w-[156px] pt-1" /></div>
                : (
                  <Button variant="primary" isLoading={isUpdateFormLoading}>
                    {
                      getOpportunityForm?.opportunity.status === 'DRAFT' && !updateOpportunityForm?.updateOpportunityForm
                        ? 'Publish for Review'
                        : 'Save Changes'
                    }
                  </Button>
                )
            }
          </div>
        </form>

        <hr className="text-muted lg:mb-7 mb-2" />

        <div className="max-w-[800px] mx-auto">

          <div className="mb-10">
            <Title>Application Form</Title>
            <Subtitle className="max-w-xl">Configure your form to capture the information you need or add custom fields for further control.</Subtitle>
          </div>

          {/* Default Fields */}
          {
            isOpportunityLoading
              ? (
                <div className="w-full">
                  <Skeleton height={60} count={9} className="mt-4 rounded-lg" />
                </div>
              ) : (
                <div className="flex flex-col gap-4">
                  {
                    watch('fields')?.length > 0 && fieldArray?.filter((field) => field.is_default === true).map((mapField) => (
                      <Field
                        field={mapField}
                        onChange={fieldArrayUpdate}
                        key={mapField.key}
                        index={fieldIndex(mapField.id)}
                      />
                    ))
                  }
                </div>

              )
          }

          <hr className="text-muted my-5 lg:my-10 max-w-[600px] mx-auto" />

          {/* Custom Fields */}
          <div className="flex flex-col gap-4">
            {
              watch('fields')?.length > 0 && fieldArray.filter((field) => field.is_default === false).map((mapField) => (
                <Field
                  field={mapField}
                  onChange={fieldArrayUpdate}
                  onRemove={fieldArrayRemove}
                  key={mapField.key}
                  index={fieldIndex(mapField.id)}
                  error={formErrors?.fields ? formErrors?.fields[fieldIndex(mapField.id)]?.title?.message : undefined}
                />
              ))
            }

            {/* Add New Field Button */}
            {
              isOpportunityLoading
                ? (
                  <div className="w-full">
                    <Skeleton height={60} className="rounded-lg" />
                  </div>
                ) : (
                  <button className="w-full rounded-lg" onClick={() => handleAppendField()}>
                    <div className="p-5 rounded-lg border border-divider flex justify-between items-center cursor-pointer">
                      <div className="w-6 h-6 text-placeholder" onClick={() => {}}>
                        <PlusCircleIcon />
                      </div>
                      <div className="flex-grow mx-8 text-left">
                        <h3 className="font-semibold text-lg text-placeholder">Add Custom Field</h3>
                      </div>
                    </div>
                  </button>
                )
            }

          </div>

        </div>

      </Container>

    </div>
  )
}

type FieldTypes = {
  field: PartialOpportunityFormField
  onChange: UseFieldArrayUpdate<OpportunityFormProps>
  onRemove?: UseFieldArrayRemove
  key: string
  index: number
  error?: string
}

const Field = ({ field, key, index, onChange, onRemove, error }: FieldTypes) => {
  const [title, setTitle] = useState<string|undefined>(field?.title)

  const handleChange = (text: string) => {
    setTitle(text)
    debouncedOnChange(text)
  }

  const debouncedOnChange = useDebouncedCallback(
    (title) => {
      onChange(index, { ...field, title: title })
    },
    1000,
  )

  return (
    <div key={key} className={`relative px-5 lg:py-5 py-3 rounded-lg border flex items-center ${!field.is_enabled && 'bg-muted'} ${error ? 'border-primary-danger' : 'border-divider'}`}>

      <button
        className="min-w-[24px] min-h-[24px]"
        onClick={() => onChange(index, { ...field, is_enabled: !field.is_enabled })}
      >
        {
          field.is_enabled
            ? <EyeIcon />
            : <EyeSlashIcon />
        }
      </button>

      <div className="flex-grow mx-8">
        {
          field.is_default
            ? (
              <h3 className={`font-semibold text-sm lg:text-lg ${field.is_enabled ? 'text-primary-dark' : 'text-placeholder'}`}>{field.title}</h3>
            ) : (
              <div className="flex flex-col gap-1">
                <input
                  value={title}
                  placeholder="Your question here..."
                  onChange={(e) => handleChange(e.target.value)}
                  className={`outline-offset-4 font-semibold text-sm lg:text-lg w-full ${field.is_enabled ? 'text-primary-dark' : 'text-placeholder'}`}
                />
                {
                  error &&
                  <p className="text-sm text-primary-danger">{error}</p>
                }
              </div>
            )
        }
      </div>

      {
        field.is_enabled &&
        <div className="flex lg:flex-row flex-col items-center max-w-max lg:gap-3 gap-1 min-w-[65px] lg:min-w-0">
          <p className="font-semibold text-xs lg:text-sm">Is Required</p>
          <Toggle
            value={field.is_required}
            onChange={() => onChange(index, { ...field, is_required: !field.is_required })}
          />
        </div>
      }

      {
        !field.is_default && onRemove &&
        <button className="w-5 h-5 absolute -right-6 lg:-right-8 text-text-gray" onClick={() => onRemove(index)}>
          <XMarkIcon />
        </button>
      }

    </div>
  )
}


export default OpportunityFormEditPage