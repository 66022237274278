import { Opportunity, Submission } from '@ec/types'
import { Tooltip } from 'react-tooltip'
import OpportunityApplicantRow from './OpportunityApplicantRow'

type OpportunityApplicantTableTypes = {
    applicants: Submission[]
    opportunity: Opportunity
}

const OpportunityApplicantTable = ({ applicants, opportunity }: OpportunityApplicantTableTypes) => {
  if (applicants?.length > 0) {
    return (
      <table className="w-full">
        <tbody>
          <Tooltip anchorId={`application-tooltip-${opportunity.id}`} content='The data that the applicant filled in while applying to your opportunity.' />
          <tr className='h-12'>
            <th className="text-left text-sm font-medium bg-muted w-[272px] rounded-l-lg pl-4">Name</th>
            <th className="text-left text-sm font-medium bg-muted w-[272px]">Email</th>
            <th className="text-sm font-medium bg-muted w-24">Existing Volunteer</th>
            <th className="text-sm font-medium bg-muted w-24">DBS Check</th>
            <th className="text-sm font-medium bg-muted w-24">Driver&apos;s License</th>
            <th className="text-sm font-medium bg-muted rounded-r-lg w-28"></th>
          </tr>
          {
            applicants.map((applicant, index) => {
              return (<OpportunityApplicantRow applicant={applicant} opportunity={opportunity} key={`opportunity-${opportunity.id}${applicant.id}`}/>)
            })
          }
        </tbody>
      </table>
    )
  } else {
    return (
      <div className="flex bg-muted w-full h-24 lg:h-32 rounded-lg mb-4">
        <p className="text-center w-full my-auto p-1">There are currently no applicants for this opportunity with the selected filters.</p>
      </div>
    )
  }
}

export default OpportunityApplicantTable