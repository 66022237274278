const VmLogo = ({ className }: { className: string }) => {
  return (
    <svg className={className} viewBox="0 0 260 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path fill="#273E8B" d="M14.1,20.1v-4.7h9.3v10.3c-1.4,1.2-2.9,2-4.7,2.5c-1.8,0.5-3.5,0.8-5.3,0.8c-2.5,0-4.6-0.5-6.3-1.5
		c-1.8-1-3.1-2.4-4.1-4.1c-0.9-1.8-1.4-3.9-1.4-6.4c0-2.3,0.5-4.4,1.5-6.2c1-1.8,2.4-3.2,4.2-4.2s3.8-1.5,6.1-1.5
		c1.9,0,3.7,0.3,5.3,0.9c1.6,0.6,3,1.7,4.1,3.1l-3.9,4c-0.7-0.7-1.5-1.3-2.3-1.7c-0.8-0.5-1.7-0.7-2.8-0.7c-1.3,0-2.4,0.3-3.3,0.9
		c-0.9,0.6-1.6,1.4-2.1,2.3c-0.5,1-0.7,2.1-0.7,3.3c0,1.9,0.6,3.5,1.7,4.7c1.1,1.2,2.6,1.8,4.4,1.8c0.7,0,1.5-0.1,2.1-0.3
		c0.7-0.2,1.3-0.5,2-0.9v-2.2H14.1z"/>
        <path fill="#273E8B" d="M26.1,20.4c0-1.6,0.4-3,1.1-4.3c0.7-1.3,1.7-2.3,3-3.1c1.3-0.8,2.8-1.1,4.5-1.1c1.7,0,3.2,0.4,4.5,1.1
		c1.3,0.8,2.3,1.8,3,3.1c0.7,1.3,1.1,2.7,1.1,4.3s-0.4,3-1.1,4.3s-1.7,2.3-3,3.1c-1.3,0.8-2.8,1.1-4.5,1.1c-1.7,0-3.2-0.4-4.5-1.1
		c-1.3-0.8-2.3-1.8-3-3.1C26.4,23.4,26.1,22,26.1,20.4z M34.6,23.9c1,0,1.7-0.3,2.3-1c0.6-0.7,0.9-1.5,0.9-2.5c0-1-0.3-1.8-0.9-2.5
		c-0.6-0.7-1.3-1-2.3-1s-1.7,0.3-2.3,1c-0.6,0.7-0.9,1.5-0.9,2.5c0,1,0.3,1.8,0.9,2.5C32.9,23.6,33.7,23.9,34.6,23.9z"/>
        <path fill="#273E8B" d="M58.7,28.4L50.5,5.7h6.6l5.1,15.8l4.8-15.8h6.4l-8.3,22.7H58.7z"/>
        <path fill="#273E8B" d="M74.5,20.4c0-1.6,0.4-3,1.1-4.3c0.7-1.3,1.7-2.3,3-3.1c1.3-0.8,2.8-1.1,4.5-1.1c1.7,0,3.2,0.4,4.5,1.1
		c1.3,0.8,2.3,1.8,3,3.1c0.7,1.3,1.1,2.7,1.1,4.3s-0.4,3-1.1,4.3s-1.7,2.3-3,3.1c-1.3,0.8-2.8,1.1-4.5,1.1c-1.7,0-3.2-0.4-4.5-1.1
		c-1.3-0.8-2.3-1.8-3-3.1C74.9,23.4,74.5,22,74.5,20.4z M83,23.9c1,0,1.7-0.3,2.3-1c0.6-0.7,0.9-1.5,0.9-2.5c0-1-0.3-1.8-0.9-2.5
		c-0.6-0.7-1.3-1-2.3-1s-1.7,0.3-2.3,1c-0.6,0.7-0.9,1.5-0.9,2.5c0,1,0.3,1.8,0.9,2.5C81.3,23.6,82.1,23.9,83,23.9z"/>
        <path fill="#273E8B" d="M94.3,28.4V5.7h5.8v22.7H94.3z"/>
        <path fill="#273E8B" d="M103.3,22.5V12.4h5.8v8.8c0,0.8,0.2,1.4,0.5,1.9c0.3,0.5,0.9,0.7,1.8,0.7c0.9,0,1.5-0.3,1.9-0.9
		c0.4-0.6,0.5-1.2,0.5-1.9v-8.6h5.8v16h-5.6l-0.2-1.6c-0.4,0.5-0.9,0.9-1.5,1.2s-1.2,0.5-1.7,0.6c-0.6,0.1-1,0.2-1.4,0.2
		c-1.7,0-3-0.5-4.1-1.5C103.8,26.4,103.3,24.7,103.3,22.5z"/>
        <path fill="#273E8B" d="M122.8,28.4v-16h5.6l0.2,1.7c0.4-0.5,0.9-0.9,1.5-1.2c0.6-0.3,1.1-0.5,1.7-0.6c0.6-0.1,1-0.2,1.4-0.2
		c1.1,0,2.1,0.2,3,0.6c0.9,0.4,1.6,1.1,2.1,2s0.8,2.1,0.8,3.6v10.1h-5.8v-8.7c0-0.8-0.2-1.4-0.5-1.9c-0.3-0.5-0.9-0.7-1.6-0.7
		c-1,0-1.7,0.3-2.1,0.9c-0.4,0.6-0.6,1.2-0.6,1.9v8.6H122.8z"/>
        <path fill="#273E8B" d="M143.4,21.6v-4.8H141v-4.4h2.4V7.1h5.8v5.3h4v4.4h-4v5.7c0,1.3,0.6,2,1.9,2c0.4,0,0.7-0.1,1.1-0.2
		s0.7-0.3,1-0.5l2.2,3.5c-0.7,0.6-1.5,1-2.4,1.3c-0.9,0.3-1.8,0.5-2.7,0.5c-1.7,0-3.1-0.3-4.1-1s-1.7-1.5-2.1-2.6
		C143.6,24.3,143.4,23.1,143.4,21.6z"/>
        <path fill="#273E8B" d="M170.4,23.5l1.8,3.7c-0.5,0.3-1.4,0.7-2.5,1.1c-1.2,0.4-2.5,0.6-4.2,0.6c-2.9,0-5.2-0.7-6.8-2.2
		c-1.6-1.5-2.4-3.6-2.4-6.3c0-1.1,0.2-2.1,0.5-3.1c0.3-1,0.9-1.9,1.6-2.8c0.7-0.8,1.6-1.5,2.6-2c1.1-0.5,2.3-0.7,3.7-0.7
		c1.5,0,2.8,0.3,4,1c1.2,0.7,2.1,1.7,2.8,3s1.1,3.1,1.1,5.1v1.1h-10.8c0,0.4,0.2,0.7,0.4,1.1c0.2,0.4,0.6,0.7,1.2,1
		c0.6,0.3,1.4,0.4,2.4,0.4c1,0,1.9-0.1,2.7-0.4C169.4,24,170,23.8,170.4,23.5z M161.9,18.7h5.7c0-0.4-0.1-0.7-0.3-1.1
		c-0.2-0.4-0.5-0.7-0.9-1c-0.4-0.3-1-0.4-1.6-0.4c-0.6,0-1.2,0.1-1.6,0.4c-0.4,0.3-0.7,0.6-0.9,1S161.9,18.3,161.9,18.7z"/>
        <path fill="#273E8B" d="M188.6,23.5l1.8,3.7c-0.5,0.3-1.4,0.7-2.5,1.1c-1.2,0.4-2.5,0.6-4.2,0.6c-2.9,0-5.2-0.7-6.8-2.2
		c-1.6-1.5-2.4-3.6-2.4-6.3c0-1.1,0.2-2.1,0.5-3.1c0.3-1,0.9-1.9,1.6-2.8c0.7-0.8,1.6-1.5,2.6-2c1.1-0.5,2.3-0.7,3.7-0.7
		c1.5,0,2.8,0.3,4,1c1.2,0.7,2.1,1.7,2.8,3s1.1,3.1,1.1,5.1v1.1H180c0,0.4,0.2,0.7,0.4,1.1c0.2,0.4,0.6,0.7,1.2,1
		c0.6,0.3,1.4,0.4,2.4,0.4c1,0,1.9-0.1,2.7-0.4C187.5,24,188.2,23.8,188.6,23.5z M180.1,18.7h5.7c0-0.4-0.1-0.7-0.3-1.1
		c-0.2-0.4-0.5-0.7-0.9-1c-0.4-0.3-1-0.4-1.6-0.4c-0.6,0-1.2,0.1-1.6,0.4c-0.4,0.3-0.7,0.6-0.9,1S180.1,18.3,180.1,18.7z"/>
        <path fill="#273E8B" d="M193.4,28.4v-16h5.6l0.2,2c0.5-0.6,1-1.2,1.7-1.6c0.7-0.4,1.5-0.7,2.4-0.7c0.5,0,0.8,0,1.2,0.1
		s0.6,0.1,0.9,0.2l-0.8,4.9c-0.5-0.1-1.1-0.2-1.7-0.2c-2.5,0-3.7,1.4-3.7,4.1v7.2H193.4z"/>
        <path fill="#273E8B" d="M207.1,10.2V5.7h6.2v4.5H207.1z M207.3,28.4v-16h5.8v16H207.3z"/>
        <path fill="#273E8B" d="M216.5,28.4v-16h5.6l0.2,1.7c0.4-0.5,0.9-0.9,1.5-1.2c0.6-0.3,1.1-0.5,1.7-0.6c0.6-0.1,1-0.2,1.4-0.2
		c1.1,0,2.1,0.2,3,0.6c0.9,0.4,1.6,1.1,2.1,2c0.5,0.9,0.8,2.1,0.8,3.6v10.1H227v-8.7c0-0.8-0.2-1.4-0.5-1.9s-0.9-0.7-1.6-0.7
		c-1,0-1.7,0.3-2.1,0.9c-0.4,0.6-0.6,1.2-0.6,1.9v8.6H216.5z"/>
        <path fill="#273E8B" d="M236.5,33.9l2.2-4c0.6,0.4,1.3,0.7,2,0.9c0.7,0.2,1.4,0.3,2.1,0.3c1.2,0,2.1-0.3,2.6-0.8
		c0.6-0.5,0.9-1.4,0.9-2.7v-0.5c-0.5,0.5-1.1,0.8-1.8,0.9c-0.7,0.1-1.4,0.2-2,0.2c-2.3,0-4-0.7-5.3-2.1c-1.2-1.4-1.9-3.2-1.9-5.4
		c0-1.9,0.4-3.4,1.2-4.7c0.8-1.3,1.9-2.3,3.3-2.9c1.4-0.7,3-1,4.8-1c1.3,0,2.5,0.1,3.7,0.3c1.2,0.2,2.4,0.6,3.6,1v13.7
		c0,3-0.7,5.2-2.2,6.5c-1.5,1.3-3.6,2-6.5,2c-1.2,0-2.4-0.1-3.5-0.4C238.6,35,237.5,34.5,236.5,33.9z M246.3,20.9V17
		c-0.6-0.2-1.2-0.3-1.8-0.3c-1.2,0-2,0.4-2.6,1.1c-0.5,0.7-0.8,1.6-0.8,2.7c0,1,0.3,1.8,0.8,2.4c0.5,0.5,1.2,0.8,1.9,0.8
		c0.8,0,1.4-0.2,1.8-0.7C246.1,22.6,246.3,21.9,246.3,20.9z"/>
      </g>
      <g>
        <path fill="#5EBC96" d="M3.2,53.3l0.6-12.5H7l3,5.5l3-5.5h3.2l0.6,12.5h-3.4l-0.3-7.3l-2.2,4.3H9.1l-2.4-4.3l-0.2,7.3H3.2z"/>
        <path fill="#5EBC96" d="M23.6,53.3l-0.1-0.8c-0.4,0.4-0.8,0.7-1.2,0.9c-0.4,0.2-0.9,0.2-1.5,0.2c-0.4,0-0.8-0.1-1.2-0.2
		s-0.8-0.4-1.1-0.8S18,51.6,18,50.9c0-0.7,0.2-1.3,0.6-1.8s0.9-0.8,1.5-1.1c0.6-0.2,1.3-0.4,2.1-0.4c0.3,0,0.5,0,0.7,0
		c0.2,0,0.4,0,0.6,0.1v-0.2c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.3-0.6-0.3s-0.4-0.1-0.7-0.1c-0.6,0-1,0.1-1.5,0.2c-0.4,0.1-0.8,0.3-1,0.5
		l-1.2-1.8c0.4-0.3,0.9-0.6,1.6-0.8c0.7-0.3,1.5-0.4,2.6-0.4c1.2,0,2.2,0.3,3,0.9s1.1,1.6,1.1,2.8v5.3H23.6z M23.5,50v-0.6
		c-0.2,0-0.5-0.1-0.9-0.1c-0.5,0-0.9,0.1-1.2,0.3c-0.3,0.2-0.4,0.5-0.4,0.7c0,0.7,0.4,1,1.1,1c0.3,0,0.6-0.1,0.9-0.3
		C23.4,50.9,23.5,50.6,23.5,50z"/>
        <path fill="#5EBC96" d="M28.4,53.3v-8.8h3.1l0.1,0.9c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.1,0.8-0.1
		c0.6,0,1.2,0.1,1.7,0.4s0.9,0.6,1.2,1.1c0.3,0.5,0.4,1.2,0.4,2v5.6h-3.2v-4.8c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.3-0.5-0.4-0.9-0.4
		c-0.6,0-0.9,0.2-1.2,0.5c-0.2,0.3-0.3,0.7-0.3,1.1v4.7H28.4z"/>
        <path fill="#5EBC96" d="M44.3,53.3l-0.1-0.8c-0.4,0.4-0.8,0.7-1.2,0.9c-0.4,0.2-0.9,0.2-1.5,0.2c-0.4,0-0.8-0.1-1.2-0.2
		s-0.8-0.4-1.1-0.8s-0.5-0.9-0.5-1.6c0-0.7,0.2-1.3,0.6-1.8s0.9-0.8,1.5-1.1c0.6-0.2,1.3-0.4,2.1-0.4c0.3,0,0.5,0,0.7,0
		c0.2,0,0.4,0,0.6,0.1v-0.2c0-0.3-0.1-0.5-0.2-0.7s-0.3-0.3-0.6-0.3s-0.4-0.1-0.7-0.1c-0.6,0-1,0.1-1.5,0.2c-0.4,0.1-0.8,0.3-1,0.5
		L39,45.4c0.4-0.3,0.9-0.6,1.6-0.8c0.7-0.3,1.5-0.4,2.6-0.4c1.2,0,2.2,0.3,3,0.9s1.1,1.6,1.1,2.8v5.3H44.3z M44.2,50v-0.6
		c-0.2,0-0.5-0.1-0.9-0.1c-0.5,0-0.9,0.1-1.2,0.3c-0.3,0.2-0.4,0.5-0.4,0.7c0,0.7,0.4,1,1.1,1c0.3,0,0.6-0.1,0.9-0.3
		C44,50.9,44.2,50.6,44.2,50z"/>
        <path fill="#5EBC96" d="M49.3,56.3l1.2-2.2c0.3,0.2,0.7,0.4,1.1,0.5c0.4,0.1,0.8,0.2,1.2,0.2c0.6,0,1.1-0.1,1.5-0.4
		c0.3-0.3,0.5-0.8,0.5-1.5v-0.3c-0.3,0.3-0.6,0.4-1,0.5c-0.4,0.1-0.8,0.1-1.1,0.1c-1.3,0-2.2-0.4-2.9-1.2c-0.7-0.8-1-1.8-1-3
		c0-1,0.2-1.9,0.7-2.6s1-1.3,1.8-1.6c0.8-0.4,1.7-0.6,2.7-0.6c0.7,0,1.4,0.1,2.1,0.2c0.7,0.1,1.3,0.3,2,0.5v7.6
		c0,1.7-0.4,2.9-1.2,3.6c-0.8,0.7-2,1.1-3.6,1.1c-0.7,0-1.3-0.1-1.9-0.2C50.5,56.9,49.9,56.6,49.3,56.3z M54.7,49.1V47
		c-0.3-0.1-0.7-0.2-1-0.2c-0.7,0-1.1,0.2-1.4,0.6c-0.3,0.4-0.4,0.9-0.4,1.5c0,0.6,0.1,1,0.4,1.3c0.3,0.3,0.6,0.4,1.1,0.4
		c0.4,0,0.7-0.1,1-0.4S54.7,49.7,54.7,49.1z"/>
        <path fill="#5EBC96" d="M67,50.6l1,2.1c-0.3,0.2-0.8,0.4-1.4,0.6c-0.6,0.2-1.4,0.3-2.3,0.3c-1.6,0-2.9-0.4-3.7-1.2
		c-0.9-0.8-1.3-2-1.3-3.4c0-0.6,0.1-1.2,0.3-1.7c0.2-0.6,0.5-1.1,0.9-1.5c0.4-0.5,0.9-0.8,1.4-1.1c0.6-0.3,1.3-0.4,2-0.4
		c0.8,0,1.5,0.2,2.2,0.6s1.2,0.9,1.6,1.7c0.4,0.7,0.6,1.7,0.6,2.8v0.6h-6c0,0.2,0.1,0.4,0.2,0.6s0.4,0.4,0.7,0.6s0.8,0.2,1.3,0.2
		c0.5,0,1-0.1,1.5-0.2C66.4,50.8,66.8,50.7,67,50.6z M62.3,47.9h3.1c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.4-0.5-0.6
		c-0.2-0.2-0.5-0.2-0.9-0.2c-0.4,0-0.6,0.1-0.9,0.2c-0.2,0.2-0.4,0.3-0.5,0.6C62.4,47.5,62.3,47.7,62.3,47.9z"/>
        <path fill="#5EBC96" d="M69.7,53.3v-8.8h3.1l0.1,1c0.3-0.4,0.7-0.7,1.2-0.9s0.9-0.3,1.4-0.3c0.6,0,1,0.1,1.3,0.2
		c0.3,0.2,0.6,0.3,0.8,0.5c0.2,0.2,0.3,0.3,0.4,0.4c0.2-0.2,0.4-0.4,0.8-0.6c0.3-0.2,0.7-0.3,1-0.4s0.7-0.2,1.1-0.2
		c1.1,0,2,0.3,2.6,0.9c0.6,0.6,0.9,1.5,0.9,2.6v5.6h-3.2v-4.7c0-0.5-0.1-0.9-0.3-1.1c-0.2-0.3-0.5-0.4-0.9-0.4s-0.8,0.1-1,0.4
		c-0.3,0.3-0.4,0.7-0.4,1.1v4.7h-3v-4.7c0-0.5-0.1-0.9-0.3-1.1c-0.2-0.3-0.5-0.4-0.9-0.4c-0.4,0-0.7,0.1-1,0.4
		c-0.3,0.3-0.4,0.7-0.4,1.1v4.7H69.7z"/>
        <path fill="#5EBC96" d="M93.5,50.6l1,2.1c-0.3,0.2-0.8,0.4-1.4,0.6c-0.6,0.2-1.4,0.3-2.3,0.3c-1.6,0-2.9-0.4-3.7-1.2
		c-0.9-0.8-1.3-2-1.3-3.4c0-0.6,0.1-1.2,0.3-1.7c0.2-0.6,0.5-1.1,0.9-1.5c0.4-0.5,0.9-0.8,1.4-1.1c0.6-0.3,1.3-0.4,2-0.4
		c0.8,0,1.5,0.2,2.2,0.6s1.2,0.9,1.6,1.7c0.4,0.7,0.6,1.7,0.6,2.8v0.6h-6c0,0.2,0.1,0.4,0.2,0.6s0.4,0.4,0.7,0.6s0.8,0.2,1.3,0.2
		c0.5,0,1-0.1,1.5-0.2C92.9,50.8,93.2,50.7,93.5,50.6z M88.8,47.9h3.1c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.4-0.5-0.6
		c-0.2-0.2-0.5-0.2-0.9-0.2c-0.4,0-0.6,0.1-0.9,0.2c-0.2,0.2-0.4,0.3-0.5,0.6C88.8,47.5,88.8,47.7,88.8,47.9z"/>
        <path fill="#5EBC96" d="M96.1,53.3v-8.8h3.1l0.1,0.9c0.2-0.3,0.5-0.5,0.8-0.7c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.1,0.8-0.1
		c0.6,0,1.2,0.1,1.7,0.4s0.9,0.6,1.2,1.1c0.3,0.5,0.4,1.2,0.4,2v5.6h-3.2v-4.8c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.3-0.5-0.4-0.9-0.4
		c-0.6,0-0.9,0.2-1.2,0.5c-0.2,0.3-0.3,0.7-0.3,1.1v4.7H96.1z"/>
        <path fill="#5EBC96" d="M107.4,49.5v-2.7h-1.3v-2.4h1.3v-2.9h3.2v2.9h2.2v2.4h-2.2V50c0,0.7,0.3,1.1,1,1.1c0.2,0,0.4,0,0.6-0.1
		c0.2-0.1,0.4-0.2,0.5-0.3l1.2,2c-0.4,0.3-0.8,0.6-1.3,0.7s-1,0.3-1.5,0.3c-1,0-1.7-0.2-2.2-0.5c-0.5-0.4-0.9-0.8-1.2-1.4
		C107.5,51,107.4,50.3,107.4,49.5z"/>
        <path fill="#5EBC96" d="M118.6,53.3V40.7h3.3v4.6h4.6v-4.6h3.3v12.5h-3.3v-4.9h-4.6v4.9H118.6z"/>
        <path fill="#5EBC96" d="M131.9,50v-5.6h3.2v4.8c0,0.4,0.1,0.8,0.3,1c0.2,0.3,0.5,0.4,1,0.4c0.5,0,0.8-0.2,1-0.5
		c0.2-0.3,0.3-0.7,0.3-1.1v-4.7h3.2v8.8h-3.1l-0.1-0.9c-0.2,0.3-0.5,0.5-0.8,0.7c-0.3,0.2-0.6,0.3-0.9,0.4s-0.6,0.1-0.8,0.1
		c-0.9,0-1.7-0.3-2.3-0.8C132.2,52.1,131.9,51.2,131.9,50z"/>
        <path fill="#5EBC96" d="M142.6,52.8V40.7h3.2v4.4c0.2-0.2,0.5-0.4,0.8-0.6s0.6-0.2,0.8-0.3c0.3,0,0.5-0.1,0.6-0.1
		c0.8,0,1.5,0.2,2.1,0.6c0.6,0.4,1,1,1.3,1.6c0.3,0.7,0.4,1.4,0.4,2.1c0,1.6-0.5,2.8-1.4,3.6s-2.2,1.3-4,1.3c-0.7,0-1.5-0.1-2.1-0.2
		S143.1,53,142.6,52.8z M145.8,48.7v2.1c0.1,0,0.3,0.1,0.5,0.1c0.2,0,0.4,0.1,0.6,0.1c0.5,0,0.9-0.2,1.3-0.5
		c0.3-0.4,0.5-0.9,0.5-1.7c0-0.3,0-0.6-0.1-0.8s-0.2-0.5-0.4-0.7c-0.2-0.2-0.5-0.3-0.8-0.3c-0.5,0-0.9,0.2-1.1,0.5
		S145.8,48.2,145.8,48.7z"/>
      </g>
      <g>
        <path fill="#273E8B" d="M25.4,6.8c0.5,1.1,1.1,2.2,1.7,3.2c0.3,0.5,0.7,0.8,1.3,1c0.3,0.1,0.6,0.1,0.8,0c0.3,0,0.5-0.1,0.8-0.3
		c0.5-0.3,0.8-0.7,1-1.3c0.1-0.6,0.1-1.1-0.2-1.6c-0.6-1-1.2-2.1-1.7-3.2c-0.1-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.4-0.3-0.7-0.4
		c-0.3-0.1-0.6-0.1-0.8,0c-0.3,0-0.5,0.1-0.8,0.3c-0.5,0.3-0.8,0.7-1,1.3c0,0.2-0.1,0.4-0.1,0.6C25.1,6.1,25.2,6.4,25.4,6.8
		L25.4,6.8z"/>
      </g>
      <g>
        <path fill="#273E8B" d="M32.9,3.4c0,1.4,0,2.8,0,4.2c0,0.5,0.2,1.1,0.6,1.5c0.4,0.4,1,0.6,1.5,0.6c0.5,0,1.1-0.2,1.5-0.6
		c0.4-0.4,0.6-0.9,0.6-1.5c0-1.4,0-2.8,0-4.2c0-0.5-0.2-1.1-0.6-1.5c-0.4-0.4-1-0.6-1.5-0.6c-0.5,0-1.1,0.2-1.5,0.6
		C33.2,2.3,32.9,2.8,32.9,3.4L32.9,3.4z"/>
      </g>
      <g>
        <path fill="#273E8B" d="M42,10.7c1.2-1.2,2.3-2.6,3.2-4c0.3-0.5,0.4-1.1,0.2-1.6c-0.1-0.5-0.5-1-1-1.3c-0.5-0.3-1.1-0.4-1.6-0.2
		c-0.5,0.2-1,0.5-1.3,1c-0.3,0.5-0.7,1-1.1,1.5c0.1-0.1,0.2-0.3,0.3-0.4c-0.6,0.7-1.2,1.4-1.8,2c-0.4,0.4-0.6,1-0.6,1.5
		c0,0.5,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.6,1.5,0.6C41,11.3,41.6,11.1,42,10.7L42,10.7z"/>
      </g>
    </svg>

  )
}

export default VmLogo