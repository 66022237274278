import Skeleton from 'react-loading-skeleton'

const OpportunityApplicantBoxSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex lg:justify-between flex-wrap gap-y-4 px-6 items-center">

        <div className="flex flex-wrap gap-4 lg:w-1/2 w-full">
          <Skeleton width={130} height={76} />
          <div className="flex flex-col my-auto w-full max-w-[17.375rem]">
            <Skeleton height={24} className="max-w-[17.375rem] mt-0 " />
            <Skeleton height={20} className="max-w-[20rem] mt-1 mb-2.5" />
            <Skeleton height={12} className="max-w-[9.875rem]" />
          </div>
        </div>

        <div className="w-full lg:w-1/2">
          <div className="flex gap-4 items-center ml-0 mr-auto lg:ml-auto lg:mr-0 w-full max-w-[22.375rem]">
            <Skeleton width={40} height={40} />
            <div className="w-full"><Skeleton height={20} className="max-w-[8.5rem]" /></div>
            <div className="w-full"><Skeleton height={20} className="max-w-[9.375rem]" /></div>
          </div>
        </div>

      </div>

      <div>
        <Skeleton height={48} />
        <div className="overflow-x-auto">
          <table className="w-full table-fixed">
            <tbody>
              <tr>
                <th className="w-36"></th>
                <th className="w-[17rem]"></th>
                <th className="w-[17rem]"></th>
                <th className="w-32"></th>
                <th className="w-32"></th>
                <th className="w-32"></th>
                <th className="w-28"></th>
              </tr>
              {
                Array.from(new Array(5)).map((item, index) => <TableRow key={index} />)
              }
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-end my-5 mr-1 gap-2">

        <div className="w-[4.5rem]">
          <Skeleton height={44} />
        </div>

        <div className="w-12">
          <Skeleton height={44} />
        </div>

        <div className="w-[4.5rem]">
          <Skeleton height={44} />
        </div>

      </div>

    </div>
  )
}

const TableRow = () => (
  <tr className="border-b border-divider">
    <td>
      <div className="max-w-[2.5rem] mx-auto">
        <Skeleton height={40} width={40} />
      </div>
    </td>
    <td className="flex items-center gap-5 py-3">
      <Skeleton width={50} height={50} />
      <div className="flex flex-col gap-1">
        <Skeleton width={96} height={20} />
        <Skeleton width={102} height={20} />
      </div>
    </td>
    <td><Skeleton width={150} height={16} /></td>
    <td><Skeleton width={19} height={19} /></td>
    <td><Skeleton width={19} height={19} /></td>
    <td><Skeleton width={19} height={19} /></td>
    <td>
      <div className="max-w-[5.5rem] flex items-center gap-2 justify-center">
        <Skeleton width={88} height={32} />
      </div>
    </td>
  </tr>
)

export default OpportunityApplicantBoxSkeleton