import { useMutation } from '@apollo/client'
import { CONFIRM_TWO_FACTOR, DISABLE_TWO_FACTOR, ENABLE_TWO_FACTOR } from '@ec/apollo/src/mutations/auth'
import { updateAuthUser } from 'slices/auth'
import { UserType } from '@ec/types'
import { Button, CrossIcon, Heading, Input, SpinnerIcon } from '@ec/ui'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import AccountDetailsForm from 'components/Forms/AccountDetailsForm'

type ChallengeTfaProps = {
  code: string
}

const AccountAuthenticationPage = () => {
  const dispatch = useDispatch()

  const { user, confirmedPassword } = useSelector((state: RootState) => ({
    user: state.auth.user,
    confirmedPassword: state.auth.confirmedPassword,
  }))

  const [enableTwoFactorAuthentication, {
    loading: isEnableLoading,
    data: enableTfaResponse,
  }] = useMutation<{ enableTwoFactorAuthentication: UserType }>(ENABLE_TWO_FACTOR)

  const [ConfirmTwoFactorAuthentication, {
    loading: isConfirmLoading,
    error: confirmErrors,
    data: confirmTfaResponse,
  }] = useMutation<{ confirmTwoFactorAuthentication: UserType }>(CONFIRM_TWO_FACTOR)

  const [disableTwoFactorAuthentication, {
    loading: isDisableLoading,
    data: disableTfaResponse,
  }] = useMutation<{ disableTwoFactorAuthentication: UserType }>(DISABLE_TWO_FACTOR)

  const { register, handleSubmit } = useForm<ChallengeTfaProps>()

  const onSubmit: SubmitHandler<ChallengeTfaProps> = (form) => {
    ConfirmTwoFactorAuthentication({
      variables: {
        code: form.code,
      },
    })
  }

  useEffect(() => {
    if (enableTfaResponse?.enableTwoFactorAuthentication) {
      dispatch(updateAuthUser(enableTfaResponse.enableTwoFactorAuthentication))
    }
  }, [enableTfaResponse])

  useEffect(() => {
    if (confirmTfaResponse?.confirmTwoFactorAuthentication) {
      dispatch(updateAuthUser(confirmTfaResponse.confirmTwoFactorAuthentication))
    }
  }, [confirmTfaResponse])

  useEffect(() => {
    if (disableTfaResponse?.disableTwoFactorAuthentication) {
      dispatch(updateAuthUser(disableTfaResponse.disableTwoFactorAuthentication))
    }
  }, [disableTfaResponse])

  return (
    <>

      <AccountDetailsForm />

      <Heading className="mt-[50px] mb-5">Two Factor Authentication</Heading>

      <hr className="bg-divider max-w-[900px]" />

      <div className="max-w-[900px] mt-8">
        {
          user?.two_factor_confirmed &&
          <>
            <div className="flex items-center gap-2">
              <p>Two factor authentication is currently enabled</p>
            </div>

            <div className="flex justify-end mt-4">
              {
                confirmedPassword
                  ? <Button
                    type="button"
                    onClick={() => disableTwoFactorAuthentication()}
                    isLoading={isDisableLoading}
                    variant="danger"
                  >
                    Disable Two Factor Authentication
                  </Button>
                  : (
                    <Button variant="danger" href={`/confirm-password/?from=${encodeURI('account/authentication')}`}>Disable Two Factor Authentication</Button>
                  )
              }
            </div>
          </>
        }

        {
          (user?.two_factor_enabled && !user.two_factor_confirmed) &&
          <>
            <p>Scan the QR code with your authentication app to receive a verification code</p>

            <div className="flex flex-col md:flex-row md:gap-10 mt-5">

              {
                isEnableLoading && <SpinnerIcon className="w-5 h-6" />
              }

              {
                user?.two_factor_svg &&
                <div dangerouslySetInnerHTML={{ __html: user.two_factor_svg }} />
              }

              {
                user?.two_factor_recovery_codes &&
                <div>
                  <p className="text-primary-dark font-bold">Recovery Codes</p>
                  <p>Take note of your recovery codes as you will not be able to see them again</p>
                  <ol className="columns-2 mt-6">
                    {
                      user.two_factor_recovery_codes.map((code) => (
                        <li className="text-secondary-blue font-semibold mb-2" key={code}>{code}</li>
                      ))
                    }
                  </ol>
                </div>
              }
            </div>

            <hr className="mt-10 mb-8 bg-divider" />

            <form onSubmit={handleSubmit(onSubmit)}>
              <p className="text-primary-dark font-bold">Verify Your Account</p>
              <p>Enter the code displayed in your authenticator app to <u>enable two factor authentication.</u></p>
              <div className="max-w-[650px] w-full flex items-center gap-4 mt-5">
                <Input
                  {...register('code')}
                  error={confirmErrors && 'Invalid Code'}
                  placeholder="Authentication Code"
                  required
                />
                <div>
                  <Button type="submit"
                    isLoading={isConfirmLoading}
                  >Verify Code</Button>
                </div>
              </div>
            </form>
          </>
        }


        {
          !user?.two_factor_enabled &&
          <div className='max-w-[900px]'>
            <div className="flex items-start gap-2">
              <CrossIcon className="h-6 w-6 p-1  text-white bg-red-500 rounded-full" />
              <div>
                <p><strong>Two factor authentication is currently disabled</strong></p>
                <p>Secure your account by enabling two factor authentication.</p>
              </div>
            </div>

            <div className="flex mt-6 justify-end">
              {
                confirmedPassword
                  ? <Button
                    variant='primary'
                    type="button"
                    onClick={() => enableTwoFactorAuthentication()}
                    isLoading={isEnableLoading}>
                    Enable Two Factor Authentication
                  </Button>
                  : (
                    <Button href={`/confirm-password/?from=${encodeURI('account/authentication')}`}>Enable Two Factor Authentication</Button>
                  )
              }
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default AccountAuthenticationPage