import { gql } from '@apollo/client'

export const GET_VOLUNTEER_MANAGER_DASHBOARD = gql`
  query ($timescale: String!) {
    volunteerManagerDashboard (timescale: $timescale) {
      submissions {
        difference
        pending
        accepted
        declined
        active
        new
        total
        previous_total
        predicted_hours
      }
      opportunities {
        active
        pending
        cancellations
      }
      chart {
        group
        data {
          opportunity_id
          opportunity_name
          submissions
        }
      }
    }
  }
`