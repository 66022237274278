import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import React, { useState } from 'react'

type PropTypes = {
  label?: string
  description?: string
  onChange?: (value: boolean) => void
  value?: boolean
  defaultChecked?: boolean
  disabled?: boolean
}

const Toggle = ({
  label,
  description,
  onChange,
  value,
  disabled = false,
}: PropTypes) => {
  const [enabled, setEnabled] = useState(value ?? false)

  const handleOnChange = (value: boolean) => {
    setEnabled(value)

    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      {
        label || description &&
        <span className="flex flex-grow flex-col">
          {
            label &&
            <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
              {label}
            </Switch.Label>
          }
          {
            description &&
            <Switch.Description as="span" className="text-sm text-gray-500">
              {description}
            </Switch.Description>
          }
        </span>
      }
      <Switch
        disabled={disabled}
        checked={enabled}
        onChange={handleOnChange}
        className={classNames('relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-blue focus:ring-offset-2', {
          'bg-primary-blue': enabled && !disabled,
          'bg-secondary-blue cursor-not-allowed': enabled && disabled,
          'bg-gray-200 cursor-not-allowed': !enabled && disabled,
          'bg-gray-200': !enabled,
        })}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
    </Switch.Group>
  )
}

export default Toggle