import { useMutation, useQuery } from '@apollo/client'
import { UPDATE_OPPORTUNITY } from '@ec/apollo/src/mutations/opportunity'
import { GET_OPPORTUNITY_TYPES } from '@ec/apollo/src/queries/opportunityTypes'
import { Media, Opportunity, OpportunityType } from '@ec/types'
import { Button, CalendarIcon, Checkbox, Container, FormError, FormMessage, Image, Input, Modal, OpportunityImages, RangeDatePicker, SingleDatePicker, TagIcon, Title, Toast, ToolTipButton } from '@ec/ui'
import { MapPinIcon, SquarePlusIcon, WarningIcon } from '@ec/ui/src/icons'
import { Listbox } from '@headlessui/react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useErrorHandler from 'hooks/UseErrorHandler'
import { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import classNames from 'classnames'
import { GET_OPPORTUNITY } from '@ec/apollo/src/queries/opportunities'
import { useNavigate, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { ArrowUpTrayIcon, ExclamationTriangleIcon, PhotoIcon, Square2StackIcon, TrashIcon } from '@heroicons/react/24/outline'
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { MAX_FILE_SIZE_MESSAGE } from '@ec/ui/src/config/media-library'

type OpportunityFormProps = {
  title: string
  duration: string
  street_address: string
  second_line: string
  capacity: string
  postcode: string
  city: string
  county: string
  country: string
  latitude?: number
  longitude?: number
  deadline_at?: string
  starts_at?: string
  ends_at?: string
  skills?: string
  content?: string
  manager_instructions: string
  types: OpportunityType[]
  media: File[]
  media_delete: string[]
  is_dbs_required: boolean
}

const OpportunitiesEditPage = () => {
  const { notify } = useNotifier()
  const { id } = useParams()
  const errorHandler = useErrorHandler()
  const navigate = useNavigate()
  dayjs.extend(utc)

  const [opportunityImages, setOpportunityImages] = useState<Partial<Media>[]>([])
  const uploadInputRef = useRef<any>(null)

  const [latitudeValue, setLatitudeValue] = useState('')
  const [longitudeValue, setLongitudeValue] = useState('')

  const [showDateWarning, setShowDateWarning] = useState(false)
  const [showManagerInstructionsWarning, setShowManagerInstructionsWarning] = useState(false)

  const [addressIsErroneous, setAddressIsErroneous] = useState<boolean>(false)
  const [dateTimesIsErroneous, setDateTimesIsErroneous] = useState<boolean>(false)

  const [isAddressModalOpen, setIsAddressModalOpen] = useState<boolean>(false)
  const [isDateTimeModalOpen, setIsDateTimeModalOpen] = useState<boolean>(false)
  const [isDeadlineModalOpen, setIsDeadlineModalOpen] = useState<boolean>(false)

  const [selectedDateRange, setSelectedDateRange] = useState<{ to?: string, from?: string }>()
  const [selectedDeadline, setSelectedDeadline] = useState<string | undefined>()

  const [addressText, setAddressText] = useState<string | undefined>()
  const [dateTimeText, setDateTimeText] = useState<string | undefined>()
  const [deadlineText, setDeadlineText] = useState<string | undefined>()

  const { data: opportunityTypesResponse } = useQuery<{ opportunityTypes: OpportunityType[] }>(GET_OPPORTUNITY_TYPES)
  const [updateOpportunity, { data: updateData, loading: isUpdateLoading, error: isUpdateError }] = useMutation<{ updateOpportunity: Opportunity }>(UPDATE_OPPORTUNITY)

  const isOpportunityLoading = false

  const { data: opportunityData, error: isOpportunityError } = useQuery<{ opportunity: Opportunity }>(GET_OPPORTUNITY, {
    variables: {
      id: id,
    },
  },
  )

  const { register, handleSubmit, setError, formState: { errors }, watch, getValues, setValue, control, clearErrors } = useForm<OpportunityFormProps>({
    defaultValues: {
      is_dbs_required: true,
      types: [],
      media_delete: [],
    },
  })

  // Set Form Data
  useEffect(() => {
    if (opportunityData && !isOpportunityError && !isOpportunityLoading) {
      setValue('title', opportunityData.opportunity.title)
      setValue('is_dbs_required', opportunityData.opportunity.is_dbs_required)
      setValue('content', opportunityData.opportunity.content)
      setValue('skills', opportunityData.opportunity.skills)
      setValue('manager_instructions', opportunityData.opportunity.manager_instructions)
      setValue('street_address', opportunityData.opportunity.street_address)
      setValue('second_line', opportunityData.opportunity.second_line)
      setValue('postcode', opportunityData.opportunity.postcode)
      setValue('county', opportunityData.opportunity.county)
      setValue('city', opportunityData.opportunity.city)
      setValue('country', opportunityData.opportunity.country)
      setValue('types', opportunityData.opportunity.opportunityTypes)
      setValue('starts_at', opportunityData.opportunity.starts_at
        ? dayjs(opportunityData.opportunity.starts_at).toISOString()
        : undefined,
      )

      setValue('ends_at', opportunityData.opportunity.ends_at
        ? dayjs(opportunityData.opportunity.ends_at).toISOString()
        : undefined,
      )

      setSelectedDateRange({ from: getValues('starts_at'), to: getValues('ends_at') })

      setValue('deadline_at', opportunityData.opportunity.deadline_at
        ? dayjs(opportunityData.opportunity.deadline_at).toISOString()
        : undefined,
      )

      setSelectedDeadline(opportunityData?.opportunity?.deadline_at ?? undefined)

      handleOnDateTimeModalClose()
      handleOnApplicationDeadlineModalClose()

      setValue('duration', opportunityData.opportunity.duration?.toString() ?? undefined)
      setValue('capacity', opportunityData.opportunity.capacity?.toString() ?? undefined)

      const addressDetails = [getValues('street_address'), getValues('postcode'), getValues('county'), getValues('country')]
      setAddressText(addressDetails.filter((detail) => detail ? detail.trim().length > 1 : false).join(', '))

      setOpportunityImages(opportunityData.opportunity.images.map((image) => {
        return {
          id: image.id,
          url: image.url,
        }
      }))
    }
  }, [opportunityData])

  // Handle Submit
  const onSubmit: SubmitHandler<OpportunityFormProps> = (form) => {
    if (updateData) {
      // prevent resubmitting
      return
    }

    updateOpportunity({
      variables: {
        id: id,
        input: {
          title: form.title,
          duration: form.duration,
          capacity: form.capacity ? parseInt(form.capacity) : null,
          street_address: form.street_address ? form.street_address : null,
          second_line: form.second_line ? form.second_line : null,
          postcode: form.postcode ? form.postcode : null,
          city: form.city,
          county: form.county,
          country: form.country,
          latitude: latitudeValue ? latitudeValue : null,
          longitude: longitudeValue ? longitudeValue : null,
          deadline_at: form.deadline_at ? form.deadline_at : null,
          starts_at: form.starts_at ? form.starts_at : null,
          ends_at: form.ends_at ? form.ends_at : null,
          skills: form.skills ? form.skills : null,
          manager_instructions: form.manager_instructions ? form.manager_instructions : null,
          content: form.content ? form.content : null,
          types: form.types.map((type) => type.id),
          media: form.media ? form.media : null,
          media_delete: form.media_delete ? form.media_delete : null,
          is_dbs_required: form.is_dbs_required,
        },
      },
    })
  }

  const managerInstructionsChangeHandler = (content) => {
    opportunityData?.opportunity.manager_instructions !== content.target.value ? setShowManagerInstructionsWarning(true) : setShowManagerInstructionsWarning(false)
  }

  // Handle Addresses
  const handleOnAddressModalClose = () => {
    const addressDetails = [getValues('street_address'), getValues('postcode'), getValues('county'), getValues('country')]

    geocodeByAddress(`${getValues('street_address')} ${getValues('city')} ${getValues('country')}`).then()
      .then(results => {
        getLatLng(results[0])
          .then(({ lat, lng }) => {
            setLatitudeValue(lat.toString())
            setLongitudeValue(lng.toString())
          })
          .catch((err) => {
            setLatitudeValue('')
            setLongitudeValue('')
          })
      })

    if (addressDetails.some((item) => item)) {
      setAddressText(addressDetails.filter((detail) => detail ? detail.trim().length > 1 : false).join(', '))
      setAddressIsErroneous(false)
    } else {
      setAddressText(undefined)
    }

    setIsAddressModalOpen(false)
  }

  // Handle Dates
  const handleOnDateTimeModalClose = () => {
    const dateTimeDetails = [
      getValues('starts_at'),
      getValues('ends_at'),
      getValues('duration'),
    ]

    const dateTimeStrings = [
      dateTimeDetails[0] ? `from ${dayjs(dateTimeDetails[0]).format('DD/MM/YYYY')}` : undefined,
      dateTimeDetails[1] ? `until ${dayjs(dateTimeDetails[1]).format('DD/MM/YYYY')}` : undefined,
      dateTimeDetails[2] ? `for ${dateTimeDetails[2]} hour(s) a day` : undefined,
    ]

    const string = dateTimeStrings.filter((string) => string !== undefined).join(', ')

    if (string !== '') {
      setDateTimeText(string.charAt(0).toUpperCase() + string.slice(1))
      setDateTimesIsErroneous(false)
    } else {
      setDateTimeText(undefined)
    }

    setIsDateTimeModalOpen(false)
  }

  const handleDateChange = (dates: { from?: string, to?: string }) => {
    // formatting here to avoid changing based on specific hh:mm
    dayjs(dates.from).format('DD/MM/YYYY') !== dayjs(opportunityData?.opportunity.starts_at).format('DD/MM/YYYY')
      ? setShowDateWarning(true)
      : setShowDateWarning(false)

    setSelectedDateRange({ from: dates.from ?? undefined, to: dates.to ?? undefined })
    setValue('starts_at', dates.from ?? undefined)
    setValue('ends_at', dates.to ?? undefined)
    if (selectedDateRange?.from || selectedDateRange?.to) {
      clearErrors(['starts_at', 'ends_at'])
    }
  }

  // Handle Deadline
  const handleOnApplicationDeadlineModalClose = () => {
    if (getValues('deadline_at') !== undefined) {
      clearErrors('deadline_at')
      setDeadlineText(`Your application deadline is on ${dayjs(getValues('deadline_at')).format('DD/MM/YYYY')}`)
    } else {
      setDeadlineText(undefined)
    }

    setIsDeadlineModalOpen(false)
  }

  const handleDeadlineDateChange = (date?: string) => {
    setSelectedDeadline(date)
    setValue('deadline_at', date)
  }

  // Handle Form Responses
  useEffect(() => {
    if (isUpdateError) {
      errorHandler(isUpdateError, setError)
      notify(<Toast type="error" message="There was an issue updating your opportunity, please try again" />)
    }
  }, [isUpdateError])

  useEffect(() => {
    if (updateData && !isUpdateLoading) {
      notify(<Toast message="You have successfully updated your opportunity" />)
      navigate(`/opportunities/${updateData.updateOpportunity.id}`)
    }
  }, [updateData])

  useEffect(() => {
    if (errors || isUpdateError) {
      setAddressIsErroneous([
        errors.street_address?.message,
        errors.postcode?.message,
        errors.county?.message,
        errors.country?.message,
        errors.latitude?.message,
        errors.longitude?.message,
      ].some((string) => string !== undefined))

      setDateTimesIsErroneous([
        errors.starts_at?.message,
        errors.ends_at?.message,
        errors.duration?.message,
      ].some((string) => string !== undefined))
    }
  }, [errors, isUpdateError, watch])

  // Handle Selecting Types
  const addItem = (item: any) => {
    if (errors.types) {
      clearErrors('types')
    }

    if (getValues('types')) {
      if (getValues('types').map((type) => type.id).includes(item.id)) {
        const array = [...getValues('types')]
        array.splice(array.indexOf(item), 1)
        setValue('types', array)
      } else {
        setValue('types', [...getValues('types'), item])
      }
    }
  }

  const removePreviewImage = (url: string) => {
    const matchingIndex = opportunityImages.findIndex((media) => media.url === url)

    // Add to the array for ids to be deleted
    if (matchingIndex !== null && opportunityImages[matchingIndex].id) {
      setValue('media_delete', getValues('media_delete').concat(opportunityImages[matchingIndex].id!))
    }

    // Filter out of current preview urls
    setOpportunityImages(opportunityImages.filter((media) => media.url !== url))
  }

  return (
    <>
      <div>

        <Container className="lg:!px-2 px-8 pb-32">
          {(showDateWarning || showManagerInstructionsWarning) && (
            <div className="flex gap-2 p-4 bg-yellow-200 rounded-lg outline outline-yellow-300 mb-6">
              <div>
                <ExclamationTriangleIcon className='h-6' />
              </div>
              <p>
                You have changed the following attributes of your opportunity:
                <ul className='list-disc py-2 px-6'>
                  {showDateWarning && <li>Opportunity Start Date</li>}
                  {showManagerInstructionsWarning && <li>Manager instructions</li>}
                </ul>
                Updating this information will notify all applicants, be sure this information is correct before saving your changes.
              </p>
            </div>
          )}
          <form onSubmit={(e) => {
            e.preventDefault()
            clearErrors()
            handleSubmit(onSubmit)(e)
          }} className="flex lg:flex-row flex-col justify-between mb-5">
            <Title className="!m-0">Update Existing Opportunity</Title>
            <div className="lg:mt-0 mt-2 flex flex-wrap gap-3">
              <Button type="button" variant="secondary" onClick={() => navigate('/opportunities/' + (updateData ? updateData?.updateOpportunity?.id : id))}>Cancel</Button>
              <Button variant="primary" isLoading={isUpdateLoading} disabled={!!updateData}>Save Changes</Button>
            </div>
          </form>

          <hr className="text-muted lg:mb-7 mb-2" />

          {/* Form Body */}
          <div>

            <div className="flex flex-col gap-2 lg:mb-10 mb-5">

              {/* Title */}
              {
                isOpportunityLoading
                  ? (
                    <div className="w-full">
                      <Skeleton height={36} className="max-w-[34.5rem] mb-5" />
                    </div>
                  )
                  : (
                    <div className="flex flex-wrap items-center mb-5">
                      <input
                        {...register('title')}
                        placeholder="Add opportunity title"
                        className={`text-xl lg:text-3xl font-semibold lg:leading-[3.125rem] leading-8 p-1 ${errors.title ? 'placeholder:text-primary-danger' : ''} max-w-[50rem] w-full`}
                      />
                      {
                        errors?.title &&
                        <p className="flex text-primary-danger text-sm lg:ml-2">
                          <WarningIcon className="h-5 w-5 inline mr-2 my-auto" />{errors.title?.message}
                        </p>
                      }
                    </div>
                  )
              }

              {/* Address */}
              {
                isOpportunityLoading
                  ? (
                    <div className="w-full">
                      <Skeleton height={28} className="max-w-[22.5rem]" />
                    </div>
                  )
                  : (
                    <div className="flex flex-wrap max-w-[50rem]">
                      <button onClick={() => setIsAddressModalOpen(true)} className={`${addressIsErroneous ? 'border-primary-danger text-primary-danger' : 'border-divider text-placeholder'} flex w-full border p-2.5 lg:p-5 rounded-xl cursor-pointer focus:ring-primary-blue`}>
                        <MapPinIcon className="lg:m-1 lg:h-5 lg:w-5 w-4 h-4 inline text-primary-blue mr-2" />
                        <p
                          className={`text-sm text-left lg:text-base lg:p-1 lg:leading-6 max-w-md cursor-pointer ${addressIsErroneous ? 'text-primary-danger' : 'text-primary-blue'}`}
                        >
                          {addressText ?? 'Add opportunity address'}
                        </p>
                      </button>
                      {
                        addressIsErroneous &&
                        <p className="flex text-primary-danger text-sm items-center lg:ml-2 lg:mt-0 mt-2">
                          <WarningIcon className="h-5 w-5 inline mr-2" />Check your address details for errors.
                        </p>
                      }
                    </div>
                  )
              }
            </div>

            {/* Date Selections */}

            <div className="flex flex-col gap-3 max-w-[50rem] lg:mb-8 mb-4">
              {isOpportunityLoading
                ? (
                  <>
                    <Skeleton height={82} />
                    <Skeleton height={82} />
                  </>
                )
                : (
                  <>
                    <button className={`${dateTimesIsErroneous ? 'border-primary-danger text-primary-danger' : 'border-divider text-placeholder'} w-full border p-2.5 lg:p-5 rounded-xl cursor-pointer focus:ring-primary-blue`} onClick={() => setIsDateTimeModalOpen(true)}>
                      <div className="flex lg:items-center w-full gap-5">
                        <div className="bg-muted rounded-md p-[0.6875rem] w-[2.5rem] h-[2.5rem] my-auto">
                          <CalendarIcon className="w-[1.125rem] h-[1.125rem] text-text-gray" />
                        </div>
                        <p className={`my-auto text-sm lg:text-base font-semibold text-left ${dateTimeText && 'text-text-gray'}`}>
                          {dateTimeText ?? 'Select date and time'}
                        </p>
                        {
                          dateTimesIsErroneous &&
                          <div className="flex my-auto">
                            <div className="h-5 min-w-[1.25rem] mr-2">
                              <WarningIcon className="mx-auto" />
                            </div>
                            <p className="text-primary-danger text-sm text-left">Check your opportunity dates for errors.</p>
                          </div>
                        }
                      </div>
                    </button>

                    <button className={`${errors.deadline_at?.message ? 'border-primary-danger text-primary-danger' : 'border-divider text-placeholder'} w-full border p-2.5 lg:p-5 rounded-xl cursor-pointer focus:ring-primary-blue`} onClick={() => setIsDeadlineModalOpen(true)}>
                      <div className="flex lg:items-center w-full gap-5">
                        <div className="bg-muted rounded-md p-[0.6875rem] w-[2.5rem] h-[2.5rem] my-auto">
                          <CalendarIcon className="w-[1.125rem] h-[1.125rem] text-text-gray" />
                        </div>
                        <p className={`my-auto text-sm lg:text-base font-semibold text-left ${deadlineText && 'text-text-gray'}`}>
                          {deadlineText ?? 'Select application deadline'}
                        </p>
                        {
                          errors.deadline_at?.message &&
                          <div className="flex my-auto">
                            <div className="h-5 min-w-[1.25rem] mr-2">
                              <WarningIcon className="mx-auto" />
                            </div>
                            <p className="text-primary-danger text-sm text-left">{errors.deadline_at.message}</p>
                          </div>
                        }
                      </div>
                    </button>
                  </>
                )
              }

            </div>

            {/* Gallery */}
            <Controller
              name="media"
              control={control}
              render={({ field }) => {
                const handleChange = async (files: FileList | null) => {
                  if (files) {
                    setOpportunityImages(opportunityImages.concat(Object.values(files).map((file) => {
                      return {
                        url: URL.createObjectURL(file),
                      }
                    })))
                  }

                  if (files !== null) {
                    field.onChange((field.value ?? []).concat(Array.from(files)))
                  }
                }
                return (
                  <>
                    {
                      isOpportunityLoading
                        ? (
                          <div className="w-full">
                            <Skeleton height={40} className="max-w-[50rem]" />
                          </div>
                        ) : (
                          <div className="bg-divider rounded-lg max-w-[800px] w-full p-2 text-black">
                            <h2 className="flex items-center text-xl font-semibold">
                              <PhotoIcon className="w-5 h-5 inline ml-1 mr-2" />Uploads
                            </h2>
                          </div>
                        )
                    }

                    <div className="max-w-[800px] mt-4">

                      {
                        opportunityImages.length > 0 &&
                        <button onClick={() => (uploadInputRef.current?.click())} type="button" className="transition-colors bg-primary-blue p-2 text-white rounded-t-lg w-full hover:bg-secondary-blue focus:ring-primary-blue">
                          Upload More
                        </button>
                      }

                      <div className={`flex flex-col items-center justify-center gap-3 min-h-[11.25rem] max-h-[22.5rem] overflow-y-auto rounded-b-lg border-dashed ${errors?.media?.message ? 'border-primary-danger' : 'border-divider'} border-2 ${opportunityImages.length > 0 ? 'border-t-0' : 'border-t-1 rounded-t-lg'}`}>

                        {
                          opportunityImages.length === 0 &&
                          <button type="button" className="w-full rounded-lg" onClick={() => (uploadInputRef.current?.click())}>
                            <div className={`${errors?.media?.message ? 'text-primary-danger' : 'text-text-gray'} max-w-max m-auto`}>
                              <ArrowUpTrayIcon className="w-11 h-9 mx-auto" />
                              <p className="mt-3 font-semibold text-lg text-center">Upload</p>
                              <FormMessage text={MAX_FILE_SIZE_MESSAGE} />
                            </div>
                          </button>
                        }

                        {
                          errors?.media?.message &&
                          <p className="flex items-center text-primary-danger text-sm lg:ml-2">
                            <WarningIcon className="h-5 w-5 inline mr-2" />{errors?.media?.message}
                          </p>
                        }

                        {
                          opportunityImages.length > 0 &&
                          <div className="p-3 w-full">
                            <div className="flex flex-wrap justify-start w-full">
                              {
                                isOpportunityLoading
                                  ? Array.from(Array(3)).map((index) => (
                                    <div key={`upload-skeleton${index}`} className="w-1/3 lg:w-1/5 lg:p-1 p-0.5 aspect-square" >
                                      <Skeleton className="h-full" />
                                    </div>
                                  ))
                                  : opportunityImages.map((media, index) => {
                                    return (
                                      <div key={`media-${index}`} className="group w-1/3 lg:w-1/5">
                                        <div className="relative p-2 rounded-lg border border-muted m-1">
                                          <button
                                            type="button"
                                            onClick={() => removePreviewImage(media.url!)}
                                            className="transition-opacity opacity-100 lg:opacity-50 focus:opacity-100 group-hover:opacity-100 flex right-0 absolute w-6 h-6 rounded-full lg:mt-2 lg:mr-2 mt-1 mr-1 p-1 bg-red-500"
                                          >
                                            <TrashIcon className="shadow-lg text-white m-auto" />
                                          </button>
                                          <Image source={media.url} height={150} className="aspect-square object-cover rounded-lg lg:p-1 p-0.5" />
                                          {
                                            (errors.media && errors.media[index]) &&
                                            <FormError text={errors.media[index]?.message ?? ''} />
                                          }
                                        </div>
                                      </div>
                                    )
                                  })
                              }
                            </div>
                          </div>
                        }

                      </div>

                    </div>

                    <div className="mb-4 lg:mb-8 mt-4">

                      {
                        isOpportunityLoading
                          ? (
                            <div className="w-full mb-4">
                              <Skeleton height={40} className="max-w-[50rem]" />
                            </div>
                          ) : (
                            <div className="bg-divider rounded-lg max-w-[800px] w-full p-2 text-primary-dark mb-4">
                              <h2 className="flex items-center text-xl font-semibold">
                                <Square2StackIcon className="w-5 h-5 inline ml-1 mr-2" />Image Preview
                              </h2>
                            </div>
                          )
                      }

                      <OpportunityImages
                        isLoading={isOpportunityLoading}
                        images={opportunityImages}
                        urls={opportunityImages.map((image) => image.url)}
                      />


                    </div>

                    <input
                      multiple
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      className="hidden"
                      ref={uploadInputRef}
                      onChange={(input) => handleChange(input.target?.files)}
                    />
                  </>
                )
              }
              }
            />

            {/* Tags */}
            {
              isOpportunityLoading
                ? (
                  <div className="w-full mb-10 ">
                    <Skeleton height={28} className="max-w-[240px]" />
                  </div>
                )
                : (
                  <Listbox as="div" value={watch('types')} onChange={addItem} className="relative mb-10">
                    <Listbox.Button className={`w-full border lg:pl-4 pl-2 lg:pr-5 pr-2.5 py-2.5 lg:py-5 rounded-xl cursor-pointer focus:ring-primary-blue max-w-[50rem] ${errors?.types ? 'border-primary-danger' : 'border-divider'}`}>

                      <div className="flex">

                        <div className="min-w-[2rem] h-8 relative lg:mt-0.5">
                          <TagIcon className="absolute top-0 left-1 w-7 h-7 z-10" />
                          <div className="absolute bottom-0 right-0 w-[1.375rem] h-[1.375rem] bg-primary-yellow" />
                        </div>

                        <div className="flex-grow mx-5 ">
                          <div className="flex flex-grow flex-wrap gap-2 items-center max-w-[37.5rem] h-full">
                            {
                              watch('types').length > 0 ? (
                                getValues('types').map((type) => (
                                  <div key={`selected-types-${type.id}`} className="bg-off-green text-sm font-medium py-2 px-3 rounded-md">
                                    {type.name}
                                  </div>
                                ))
                              ) : (
                                <p className={`font-semibold text-sm lg:text-base mt-1 ${errors?.types ? 'text-primary-danger' : 'text-placeholder'}`}>Add tags</p>
                              )
                            }
                            {
                              errors?.types?.message &&
                              <div className="flex text-primary-danger lg:mt-1">
                                <div className="h-5 min-w-[1.25rem] mr-2">
                                  <WarningIcon className="mx-auto" />
                                </div>
                                <p className="text-sm text-left">{errors?.types?.message}</p>
                              </div>
                            }
                          </div>
                        </div>

                        <div className="min-w-[2rem] h-8 flex lg:mt-0.5">
                          <SquarePlusIcon className="w-5 h-5 text-placeholder m-auto" />
                        </div>
                      </div>

                    </Listbox.Button>
                    <Listbox.Options className="absolute z-10 bg-white border border-divider shadow-md flex flex-wrap max-w-[50rem] gap-1 max-h-32 overflow-y-scroll rounded-md mt-2 p-2">
                      {
                        opportunityTypesResponse?.opportunityTypes && opportunityTypesResponse?.opportunityTypes.map((type, index) => (
                          <Listbox.Option
                            as="div"
                            value={type}
                            key={`types-${type.id}`}
                            className={({ active }) =>
                              classNames(
                                active && 'text-white bg-primary-green',
                                getValues('types').map((type) => type.id).includes(type.id) && 'bg-off-green text-black hover:text-white hover:bg-primary-green',
                                'py-1 px-2 rounded-md text-sm cursor-pointer',
                              )
                            }
                          >
                            {type.name}
                          </Listbox.Option>
                        ))
                      }
                    </Listbox.Options>
                  </Listbox>
                )
            }

            {/* Checkboxes */}
            <div className="mb-14">
              {
                isOpportunityLoading
                  ? (
                    <div className="w-full">
                      <Skeleton height={78} />
                    </div>
                  )
                  : (
                    <>
                      <hr className="mt-5 mb-3" />
                      <Checkbox {...register('is_dbs_required')} label="Is DBS check required?" />
                    </>
                  )
              }
            </div>

            {/* Description */}
            <div className="mb-[3.75rem]">
              {isOpportunityLoading
                ? (
                  <Skeleton height={156} />
                )
                : (
                  <>
                    <div className="flex flex-wrap items-center gap-3">
                      <Title className={`!m-0 leading-7 ${errors?.content?.message && '!text-primary-danger'}`}>Opportunity Description</Title>
                      {
                        errors?.skills?.message &&
                        <p className="flex items-center text-primary-danger text-sm lg:ml-2">
                          <WarningIcon className="h-5 w-5 inline mr-2" />{errors?.content?.message}
                        </p>
                      }
                    </div>
                    <hr className="mt-5 mb-3" />
                    <textarea
                      {...register('content')}
                      placeholder="Write your description here"
                      className="p-5 w-full" />
                  </>
                )
              }
            </div>

            {/* Skills & Credentials */}
            <div className="mb-[3.75rem]">
              {isOpportunityLoading
                ? (
                  <Skeleton height={156} />
                )
                : (
                  <>
                    <div className="flex flex-wrap items-center gap-3">
                      <div className="flex items-center gap-4">
                        <Title className={`!m-0 leading-7 ${errors?.skills?.message && '!text-primary-danger'}`}>Skills and Credentials</Title>
                        <p className="text-lg text-placeholder">Optional</p>
                      </div>
                      {
                        errors?.skills?.message &&
                        <p className="flex items-center text-primary-danger text-sm lg:ml-2">
                          <WarningIcon className="h-5 w-5 inline mr-2" />{errors?.skills?.message}
                        </p>
                      }
                    </div>
                    <hr className="mt-5 mb-3" />
                    <textarea
                      {...register('skills')}
                      placeholder="Write your requirements here"
                      className="p-5 w-full" />
                  </>
                )
              }
            </div>

            {/* Accepted instructions */}
            <div className="mb-3">
              <div className="flex flex-wrap items-center gap-3">
                <div className="flex items-center gap-4">
                  <Title className={`!m-0 leading-7 ${errors?.manager_instructions?.message && '!text-primary-danger'}`}>Manager Instructions</Title>
                </div>
                <ToolTipButton
                  id='manager-instructions-tooltip'
                  content='The instructions that your volunteer(s) will use to help them when they start volunteering with you. Examples of this are directions to your location or dress codes for the day'
                  className={`h-4 ${errors?.manager_instructions?.message ? '!text-primary-danger' : 'text-text-gray-light'}`} type='question'
                />
                {
                  errors?.manager_instructions?.message &&
                  <p className="flex items-center text-primary-danger text-sm lg:ml-2">
                    <WarningIcon className="h-5 w-5 inline mr-2" />{errors?.manager_instructions?.message}
                  </p>
                }
              </div>
              <hr className="mt-5 mb-3" />
              <textarea
                {...register('manager_instructions')}
                placeholder="Write your instructions here"
                className="p-5 w-full"
                onChange={managerInstructionsChangeHandler}
              />
            </div>

            {/* volunteer capacity */}
            <div className="mb-[3.75rem]">
              {isOpportunityLoading
                ? (
                  <Skeleton height={156} />
                )
                : (
                  <>
                    <div className="flex flex-wrap items-center gap-3">
                      <div className="flex items-center gap-4">
                        <Title className={`!m-0 leading-7 ${errors?.skills?.message && '!text-primary-danger'}`}>Volunteer Capacity</Title>
                        <p className="text-lg text-placeholder">Optional</p>
                      </div>
                      {
                        errors?.skills?.message &&
                        <p className="flex items-center text-primary-danger text-sm lg:ml-2">
                          <WarningIcon className="h-5 w-5 inline mr-2" />{errors?.skills?.message}
                        </p>
                      }
                    </div>
                    <hr className="mt-5 mb-3" />
                    <input
                      type="number"
                      {...register('capacity')}
                      placeholder="The maximum number of volunteers to be part of this opportunity (10, 100, 1000 etc.)"
                      step="1"
                      min="1"
                      className="p-5 w-full" />
                  </>
                )
              }

            </div>

          </div>

        </Container>

      </div>

      {/* Address Details */}
      <AddressModal
        isOpen={isAddressModalOpen}
        onClose={() => handleOnAddressModalClose()}
        errors={errors}
        register={register}
        setValue={setValue}
        setLatitudeValue={setLatitudeValue}
        setLongitudeValue={setLongitudeValue}
      />

      {/* Date and Time Details */}
      <DateTimeModal
        isOpen={isDateTimeModalOpen}
        onClose={() => handleOnDateTimeModalClose()}
        errors={errors}
        register={register}
        selectedDate={selectedDateRange}
        onDateChange={(dates) => handleDateChange(dates)}
      />

      {/* Application Deadline */}
      <DeadLineModal
        isOpen={isDeadlineModalOpen}
        onClose={() => handleOnApplicationDeadlineModalClose()}
        selectedDate={selectedDeadline}
        onDateChange={(date) => handleDeadlineDateChange(date)}
        errors={errors}
      />
    </>
  )
}

type AddressModalProps = {
  isOpen: boolean,
  onClose: () => void,
  errors?: any
  register?: any
  setValue: any
  setLatitudeValue: Dispatch<SetStateAction<string>>
  setLongitudeValue: Dispatch<SetStateAction<string>>
}

const AddressModal = ({ isOpen, onClose, register, errors, setValue, setLatitudeValue, setLongitudeValue }: AddressModalProps) => {

  const fillAddress = async (address) => {
    let streetAddress = ''
    setValue('street_address', null)
    setValue('second_line', null)
    setValue('postcode', null)
    setValue('city', null)
    setValue('county', null)
    setValue('country', null)
    setLatitudeValue('')
    setLongitudeValue('')
    geocodeByAddress(address.label)
      .then(results => {
        results[0].address_components.forEach((addressComponent) => {
          switch (addressComponent.types[0]) {
            case 'street_number':
              streetAddress += `${addressComponent.long_name} `
              break

            case 'route':
              streetAddress += `${addressComponent.long_name} `
              break

            case 'postal_town':
              setValue('city', addressComponent.long_name)
              break

            case 'postal_code':
              setValue('postcode', addressComponent.long_name)
              break

            case 'country':
              setValue('country', addressComponent.long_name)
              break

            default:
              break
          }
        })
        setValue('street_address', streetAddress.trim())
        getLatLng(results[0])
          .then(({ lat, lng }) => {
            setLatitudeValue(lat.toString())
            setLongitudeValue(lng.toString())
          })
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Address" />
      <Modal.Content>
        <p className="text-text-gray-light text-sm mb-5">Share the details of your opportunity&apos;s address, these details will save to your form submission.</p>
        <div className="space-y-7">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Search for an address
            </label>
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              selectProps={{
                onChange: (e) => fillAddress(e),
                placeholder: 'Start typing here',
                className: 'mt-1 block w-full disabled:bg-gray-200 appearance-none rounded-md border border-gray-300 py-1 placeholder-gray-400 shadow-sm focus:border-primary-blue focus:outline-none focus:ring-primary-blue sm:text-sm',
                styles: {
                  control: () => ({
                    border: 'none',
                    width: '100%',
                  }),
                  indicatorsContainer: () => ({
                    display: 'none',
                  }),
                },
              }}
            />
          </div>
          <div className="space-y-7">
            <Input {...register('street_address')} label="Street Address" required error={errors.street_address?.message} />
            <Input {...register('second_line')} label="Second Line" error={errors.second_line?.message} showOptionalLabel />
            <Input {...register('postcode')} label="Postcode" required error={errors.postcode?.message} />
          </div>
          <div className="flex gap-4">
            <Input {...register('county')} label="County" required error={errors.county?.message} />
            <Input {...register('city')} label="City" required error={errors.city?.message} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer className="flex">
        <div className="ml-auto">
          <Button onClick={onClose}>Save</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

type DateTimeModalProps = {
  isOpen: boolean,
  onClose: () => void,
  errors?: any
  register?: any
  onDateChange: (data: any) => void
  selectedDate?: { to?: string, from?: string }
}

const DateTimeModal = ({ isOpen, onClose, register, errors, onDateChange, selectedDate }: DateTimeModalProps) => {
  const [range, setRange] = useState<{ to?: string, from?: string }>()

  const handleRangeSelection = (startsAt?: string, endsAt?: string) => {
    setRange({ to: endsAt, from: startsAt })
  }

  // Set form depending on which tab they leave selected
  const handleOnClose = () => {
    onDateChange({ from: range?.from, to: range?.to })
    onClose()
  }

  // Set the default range if they chose to edit an originally open ended event
  useEffect(() => {
    if (selectedDate) {
      setRange({ to: selectedDate.to ?? dayjs(selectedDate.from).add(1, 'day').toString(), from: selectedDate.from })
    }
  }, [selectedDate])

  return (
    <Modal isOpen={isOpen} onClose={() => handleOnClose()}>
      <Modal.Header title="Opportunity Dates" />
      <Modal.Content>
        <p className="text-text-gray-light text-sm mb-5">Share the details of your opportunity&apos;s event times, these details will save to your form submission.</p>
        <div className="space-y-8">
          <Input
            {...register('duration', { min: 0.05, max: 12 })}
            error={errors.duration?.message}
            type="number"
            label="Duration (Hours a Day)"
            showOptionalLabel
            step={0.05}
            min={0.05}
            max={12}
          />

          <RangeDatePicker
            startBlank
            dates={range}
            onChange={(date) => handleRangeSelection(date?.from, date?.to)}
            className="max-w-min bg-white !mx-0"
          />
          <div className="bg-white">
            {
              errors?.starts_at?.message &&
              <p className="text-sm text-primary-danger mt-2">{errors?.starts_at?.message}</p>
            }
            {
              errors?.ends_at?.message &&
              <p className="text-sm text-primary-danger mt-2">{errors?.ends_at?.message}</p>
            }
          </div>

        </div>
      </Modal.Content>
      <Modal.Footer className="flex">
        <div className="ml-auto">
          <Button onClick={() => handleOnClose()}>Save</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

type DeadlineModalProps = {
  isOpen: boolean,
  onClose: () => void,
  errors?: any
  register?: any
  onDateChange: (data: any) => void
  selectedDate?: string
}

const DeadLineModal = ({ isOpen, onClose, onDateChange, selectedDate }: DeadlineModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header title="Opportunity Dates" />
      <Modal.Content>
        <p className="text-text-gray-light text-sm mb-5">Share the deadline of your opportunity, these details will save to your form submission.</p>
        <div className="flex flex-col">
          <p className="text-sm text-text-gray font-medium w-full border-b-divider border-b pb-1">Application Deadline Selection</p>
          <div className="mt-2">
            <SingleDatePicker
              date={selectedDate}
              onChange={onDateChange}
              className="max-w-min bg-white !mx-0 !my-1"
              layout="buttons"
            />
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer className="flex">
        <div className="ml-auto">
          <Button onClick={onClose}>Save</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default OpportunitiesEditPage