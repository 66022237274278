import { gql } from '@apollo/client'

export const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession($product_id: String!) {
    createCheckoutSession(product_id: $product_id)
  }
`

export const CREATE_BILLING_PORTAL = gql`
  mutation createBillingPortal {
    createBillingPortal
  }
`