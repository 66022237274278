import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { SubscriptionLimits } from '@ec/types'
import { updateAuthUser } from 'slices/auth'

const useSubscriptionConstraints = () => {
  const dispatch = useDispatch()

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const [subscriptionLimits, setSubscriptionLimts] = useState<undefined | SubscriptionLimits>(undefined)

  const canCreateOpportunities = useMemo(() => {
    if (user) {
      return user.canCreateOpportunities
    }

    return false
  }, [user])

  const canCreateVolunteers = useMemo(() => {
    if (user && user?.organisation?.subscriptionLimits) {
      return user.organisation.subscriptionLimits.volunteerCount !== user.organisation.subscriptionLimits.volunteerLimit
    }

    return false
  }, [user])

  useEffect(() => {
    if (user?.organisation?.subscriptionLimits) {
      setSubscriptionLimts(user.organisation.subscriptionLimits)
    }
  }, [user?.organisation])

  const incrementVolunteerCount = () => {
    const updatedUser = JSON.parse(JSON.stringify(user))
    updatedUser.organisation.subscriptionLimits.volunteerCount = user!.organisation.subscriptionLimits.volunteerCount + 1

    dispatch(updateAuthUser(updatedUser))
  }

  const incrementOpportunityCount = () => {
    const updatedUser = JSON.parse(JSON.stringify(user))
    updatedUser.organisation.subscriptionLimits.opportunityCount = user!.organisation.subscriptionLimits.opportunityCount + 1

    dispatch(updateAuthUser(updatedUser))
  }

  return { canCreateOpportunities, canCreateVolunteers, subscriptionLimits, incrementOpportunityCount, incrementVolunteerCount }
}

export default useSubscriptionConstraints