import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from 'App'
import RegisterPage from 'pages/auth/registerPage'
import ProtectedRoute from 'ProtectedRoute'
import PageLayout from 'pages/layout'
import LoginPage from 'pages/auth/loginPage'
import AccountAuthenticationPage from 'pages/account/accountAuthentication'
import ConfirmPasswordPage from 'pages/other/confirmPassword'
import AccountPageOutlet from 'pages/account/outlet'
import TwoFactorChallengePage from 'pages/auth/twoFactorChallengePage'
import ForgotPasswordPage from 'pages/auth/forgotPasswordPage'
import ResetPasswordPage from 'pages/auth/resetPasswordPage'
import AccountSettingsPage from 'pages/account/accountSettings'
import OpportunitiesCreatePage from 'pages/opportunities/create'
import OpportunitiesPage from 'pages/opportunities'
import AccountOrganisationPage from 'pages/account/accountOrganisation'
import ApplicantsPage from 'pages/applicants'
import VolunteersPage from 'pages/volunteers'
import SubscriptionPage from 'pages/subscriptions'
import AccountBillingPage from 'pages/account/accountBilling'
import OpportunitySlug from 'pages/opportunities/show'
import OpportunitiesEditPage from 'pages/opportunities/edit'
import OpportunityFormEditPage from 'pages/opportunities/form/edit'
import DashboardPage from 'pages/dashboard'
import ErrorPage from 'pages/other/errorPage'
import VerifyEmail from '@ec/ui/src/pages/verifyEmail'
import ScrollToTop from '@ec/ui/src/ScrollToTop'
import SettingUpAccountPage from 'pages/other/settingUpAccount'

const Router = () => {
  return (
    <BrowserRouter>
      <App>
        <ScrollToTop>
          <Routes>

            <Route element={<ProtectedRoute />}>

              <Route element={<PageLayout />}>

                <Route path="/" element={<DashboardPage />} />

                <Route path="/opportunities">
                  <Route path="" element={<OpportunitiesPage />} />
                  <Route path="create" element={<OpportunitiesCreatePage />} />
                  <Route path=":id/form/edit" element={<OpportunityFormEditPage />} />
                  <Route path=":id/edit" element={<OpportunitiesEditPage />} />
                  <Route path=":id" element={<OpportunitySlug />} />
                </Route>

                <Route path="/applicants" element={<ApplicantsPage />} />
                <Route path="/volunteers" element={<VolunteersPage />} />

                <Route path="account" element={<AccountPageOutlet />}>
                  <Route path="settings" element={<AccountSettingsPage />} />
                  <Route path="organisation" element={<AccountOrganisationPage />} />
                  <Route path="authentication" element={<AccountAuthenticationPage />} />
                  {/* <Route path="notifications" element={<div className="m-2">TBD</div>} /> This is disabled per ECMVP-457 */}
                  <Route path="billing" element={<AccountBillingPage />} />
                </Route>

                <Route path="/subscription">
                  <Route path="" element={<SubscriptionPage />} />
                  <Route path="setup" element={<SettingUpAccountPage />} />
                </Route>

                <Route path="confirm-password" element={<ConfirmPasswordPage />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
              </Route>

            </Route>

            <Route element={<PageLayout />}>
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/login/challenge" element={<TwoFactorChallengePage />} />
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </ScrollToTop>

      </App>
    </BrowserRouter>
  )
}

export default Router